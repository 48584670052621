/*@font-face {
	font-family: "revicons";
	fallback: fallback;
	src: url("./revicons.woff") format('woff'), url("./revicons.ttf") format('ttf'), url("./revicons.eot") format('ttf')
}*/

.react-multi-carousel-list {
	display: flex;
	align-items: center;
	overflow: hidden;
	/*position: relative;*/
	margin:0px 0px 0px 40px;
	width: 223px;
}

.react-multi-carousel-track {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	position: relative;
	transform-style: preserve-3d;
	backface-visibility: hidden;
	will-change: transform, transition
}

.react-multiple-carousel__arrow {
	position: absolute;
	/*outline: 0;
	transition: all .5s;
	border-radius: 35px;
	z-index: 1000;
	border: 0;
	background: rgba(0, 0, 0, 0.5);*/
	min-width: 40px;
	min-height: 40px;
	opacity: 1;
	cursor: pointer
}
.react-multiple-carousel__arrow:focus,.react-multiple-carousel__arrow:active{
	outline: none;
	box-shadow: none;
}
/*.react-multiple-carousel__arrow:hover {
	background: rgba(0, 0, 0, 0.8)
}*/

.react-multiple-carousel__arrow::before {
	font-size: 20px;
	color: #fff;
	display: block;
	font-family: revicons;
	text-align: center;
	z-index: 2;
	position: relative
}

.react-multiple-carousel__arrow--left {
	left: calc(-1% + 2px);
	background: url("../images/buttons/prev_carousel.svg") no-repeat center left;
}

.react-multiple-carousel__arrow--left::before {
	background: url("../images/buttons/prev_carousel.svg") no-repeat center right;
}

.react-multiple-carousel__arrow--right {
	right: calc(1% + 1px);
	background: url("../images/buttons/next_carousel.svg") no-repeat center right;
}

.react-multiple-carousel__arrow--right::before {
	background:url("../images/buttons/next_carousel.svg") no-repeat center right;
}

.react-multi-carousel-dot-list {
	position: absolute;
	bottom: 0;
	display: flex;
	left: 0;
	right: 0;
	justify-content: center;
	margin: auto;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center
}

.react-multi-carousel-dot button {
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	opacity: 1;
	padding: 5px 5px 5px 5px;
	box-shadow: none;
	transition: background .5s;
	border-width: 2px;
	border-style: solid;
	border-color: grey;
	padding: 0;
	margin: 0;
	margin-right: 6px;
	outline: 0;
	cursor: pointer
}

.react-multi-carousel-dot button:hover:active {
	background: #080808
}

.react-multi-carousel-dot--active button {
	background: #080808
}

.react-multi-carousel-item {
	transform-style: preserve-3d;
	backface-visibility: hidden;
	margin:0 6px;
	width:auto!important;
}

@media all and (-ms-high-contrast:none),
(-ms-high-contrast:active) {
	.react-multi-carousel-item {
		flex-shrink: 0 !important
	}
	.react-multi-carousel-track {
		overflow: visible !important
	}
}