/* Author: Akshay Mistry */
@font-face {
  src: url('fonts/Averta.otf');
  font-family: 'Averta';
  font-weight: 300;
}

@font-face {
  src: url('fonts/Averta_Semibold.otf');
  font-family: 'Averta';
  font-weight: 500;
}

@font-face {
  src: url('fonts/Averta_Extra_Bold.otf');
  font-family: 'Averta';
  font-weight: 800;
}

@font-face {
  src: url('fonts/Averta_Semibold_Italic.otf');
  font-family: 'Averta';
  font-weight: 500;
  font-style: italic;
}

@font-face {
  src: url('fonts/Averta_Regular_Italic.otf');
  font-family: 'Averta';
  font-weight: 300;
  font-style: italic;
}

*{
  margin: 0px;
  padding: 0px;
}

body{
  background-color: #13A5FF;
  font-family: Averta, sans-serif;
}

img.mockup{
  width: 100%;
}

.header{
  text-align: center;
  margin-top: 46px;
}

h1{
  color: #F2F7FF;
  font-weight: normal;
  font-size: 48px;
}

p{
  color: #24527E;
  font-weight: 300;
  font-size: 18px;
}

.appMockup{
  /*margin-top: 41px;*/
  position: relative;
  
  -webkit-animation-name: fade;
  -webkit-animation-duration: 3s;
  animation-name: fade;
  animation-duration: 3s;
}

@-webkit-keyframes fade {
  from {opacity: 0} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: 0} 
  to {opacity: 1}
}

.inactive{
  opacity: 0.40;
}

.section3{
  position: absolute;
  width: 1440px;
  bottom: 0;
}

.controlTray{
  background-color: #F2F7FF;
  height: 124px;
  border-radius: 11px 11px 0px 0px;
  box-shadow: 0px 0px 50px rgba(36, 82, 126, 0.16);
}

.controls{
  display: flex;
  justify-content: space-between;
}

.block1{
  margin-left: 34px;
  margin-top: 26px;
}

.block3{
  text-align: right;
  margin-right: 34px;
  margin-top: 26px;
}

.button1{
  width: 185px;
  height: 36px;
  background-color: #008cff;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 800;
  color: white;
}

.logo{
  height: 124px;
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blLogo{
  width: 134px;
  opacity: 0.30;
}

.button2{
  width: 76px;
  height: 36px;
  border: 2px solid #008cff;
  color: #008cff;
  padding-top: 1px;
  font-weight: 500;
  margin-bottom: 12px;
  border-radius: 20px;
  background-color: unset;
}

.input{
  display: flex;
}


.checkbox{
  width: 24px;
  height: 24px;
}
.sliderIndi{
  display: flex;
  justify-content: center;
  margin-top: 22px;
}

.indi{
  width: 20px;
  height: 4px;
  border-radius: 20px;
  background-color: #64C4FF;
  margin-left: 5px;
}

.active{
  background: #F2F7FF;
}

.toolTip{
  position: absolute;
  width: 325px;
  height: auto;
  background-color: #24527E;
  border-radius: 12px;
  padding: 30px;
}

.toolTip h3{
  color: #F2F7FF;
  font-size: 24px;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 0px;
}

.toolTip p{
  color: #F2F7FF;
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  margin-top: 17px;
  margin-bottom: 0px;
}

.toolTip1{
  left: 288px;
  top: 20px;
}

.toolTip2{
  right: 0px;
  bottom: 26px;
}

.toolTip3{
  left: 981px;
  top: 63px;
}

.toolTip4{
  left: 784px;
  top: 20px;
}

.toolTip::after{
  content: " ";
  width: 30px;
  height: 30px;
  position: absolute;
  transform: rotate(45deg);
  top: 72px;
  left: -8px;
  background-color: #24527e;
}

.toolTip2::after{
  top: 248px;
  left: -10px;
}

.toolTip4::after{
  top: 25px;
  left: 305px;
}
/*img.mockup{
  width: 100%;
}
 .img1, .img2, .img3, .img4{display:none;}

.header{
  text-align: center;
  margin-top: 46px;
}

h1{
  color: #F2F7FF;
  font-weight: normal;
  font-size: 48px;
}

p{
  color: #24527E;
  font-weight: 300;
  font-size: 18px;
}

.appMockup{
  
  position: relative;
  -webkit-transition: left 2s;
  -moz-transition: left 2s;
  -o-transition: left 2s;
  transition: left 2s;
}
.slider-holder
{
    
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    text-align: center;
    overflow: hidden;
}
.inactive{
  opacity: 0.40;
}

.section3{
  width: 1420px;
  margin: 20px auto 0px auto;
  position: absolute;
}

.controlTray{
  background-color: #F2F7FF;

  height: 124px;
  border-radius: 11px 11px 0px 0px;
  box-shadow: 0px 0px 50px rgba(36, 82, 126, 0.16);
}

.controls{
  display: flex;
  justify-content: space-between;
}

.block1{
  margin-left: 34px;
  margin-top: 26px;
}

.block3{
  text-align: right;
  margin-right: 34px;
  margin-top: 26px;
}

.button1{
  width: 185px;
  height: 36px;
  background-color: #008cff;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 800;
  color: white;
}

.logo{
  height: 124px;
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo a{cursor: pointer;}
.blLogo{
  width: 134px;
  opacity: 0.30;
}

.button2{
  width: 76px;
  height: 36px;
  border: 2px solid #008cff;
  color: #008cff;
  padding-top: 1px;
  font-weight: 500;
  margin-bottom: 12px;
  border-radius: 20px;
  background-color: unset;
}

.input{
  display: flex;
}


.dontShow{
  color: #F2F7FF;
  width: 24px;
  height: 24px;
}
.sliderIndi{
  display: flex;
  justify-content: center;
  margin-top: 22px;
}

.indi{
  width: 20px;
  height: 4px;
  border-radius: 20px;
  background-color: #64C4FF;
  margin-left: 5px;
}

.active{
  background: #F2F7FF;
}

.toolTip{
  position: absolute;
  width: 325px;
  height: auto;
  background-color: #24527E;
  border-radius: 12px;
  padding: 30px;
}

.toolTip h3{
  color: #F2F7FF;
  font-size: 24px;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 0px;
}

.toolTip p{
  color: #F2F7FF;
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  margin-top: 17px;
  margin-bottom: 0px;
}

.toolTip1{
  left: 288px;
  top: 20px;
}

.toolTip2{
  right: 22px;
  bottom: 26px;
  opacity: 0;
}

.toolTip3{
  left: 981px;
  top: 20px;
  opacity: 0;
}

.toolTip4{
  left: 767px;
  top: 20px;
  opacity: 0;
}

.toolTip::after{
  content: " ";
  width: 30px;
  height: 30px;
  position: absolute;
  transform: rotate(45deg);
  top: 72px;
  left: -8px;
  background-color: #24527e;
}

.toolTip2::after{
  top: 248px;
  left: -10px;
}

.toolTip4::after{
  top: 25px;
  left: 305px;
}

#appMockup2, #appMockup3, #appMockup4{
  opacity: 0;
  position: absolute;
  top:0;
  left:0;
}*/