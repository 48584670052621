@font-face {
  font-family: 'Averta-Regular';
  src: url('fonts/Averta.otf');
}
@font-face {
  font-family: 'Averta-Semibold';
  src: url('fonts/Averta_Semibold.otf');
}
@font-face {
	font-family: 'Averta-italic';
	src: url('fonts/Averta_Regular_Italic.otf');
  }
  @font-face {
	font-family: 'Averta-Semibolditalic';
	src: url('fonts/Averta_Semibold_Italic.otf');
  }
@font-face {
font-family: 'Averta-Bold';
src: url('fonts/Averta_Bold.otf');
}
@font-face {
	font-family: 'Averta-Light';
	src: url('fonts/Averta_Light.otf');
}
@font-face {
	font-family: 'Averta-Extrabold';
	src: url('fonts/Averta_Extra_Bold.otf');
}
body{
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	display: flex;
	align-items: center!important;
	
	/*height: 100vh;*/
	}
	
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border: none;
	font-family: 'Averta-Regular';
	scrollbar-color: rgba(0, 140, 255, 0.18) #aaa0;
	scrollbar-width: thin;
}
.App{
	border-radius: 10px;
    height: 896px;
	width: 1443px;
	display: flex;
	align-items: center;
	
	/*height: 100vh;*/
}

#root{
	/*border: 2px solid;
    height: 984px;
	width: 1443px;*/
	
    /*top: 0;
    right: 0;
    bottom: 0;
	left: 0;
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
-webkit-transform: translate(-50%, -50%);
-moz-transform: translate(-50%, -50%);
-o-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);*/
  
  /*font-size: 20px;
  background-color: cyan;
  border: darkgreen 5px solid;
  padding: 5px;
  z-index: 100;*/
}
::selection {
	/*color: red;*/
	background: rgba(0,140,255,0.5);
  }
  ::-moz-selection { 
	background: rgba(0,140,255,0.5);
  }
.logoImg
{
	width: 127px;
    height: 32px;
}
.menu_icon
{
	width:30px;
	height:30px;
}
.menu_icon_ac
{
	height:25px;
}
.commonWidthHeight{
	position: relative;
	width: 1440px;
	height: 896px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;
	/*border: 1px solid #d6e1f0;*/
	border-radius: 20px;
	box-shadow: 0px 7px 30px 0px #008bff30;
	z-index: 999;
	
}
.commonWidthHeight404{
	position: relative;
	width: 1440px;
	height: 896px;
	background-color: #CDE8FF;
	overflow: hidden;
	/*border: 1px solid #d6e1f0;*/
	border-radius: 20px;
	box-shadow: 0px 7px 30px 0px #008bff30;
	z-index: 999;
}
.commonWidthHeightForGuided
{
	position: relative;
	width: 1440px;
	height: 896px;
	background:transparent;
	overflow: hidden;
	z-index: 999;
}
#Interaction___Connection_Clicked_Once {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
.middlePanel
{
	float:left;
	width: 741px;
	margin-left: 58px;
}
#Header {
	/*position: absolute;*/
	/*width: 739px;
	
	margin-left: 330px;*/
	margin-top: 39px;
	overflow: visible;
	/*height: 30px;*/
}
.middlePanelOthers
{
	float:left;
	width: 1046px;
	margin-left: 56px;
}
.middlePanelOthers_Ar
{
	float:left;
	width: 1120px;
	margin-left: 29px;
}
#HeaderOthers {
	width:1046px;
	margin-top: 43px;
	overflow: visible;
	height: 30px;
}
#today{
	background-color: #008CFF;
    padding: 8px;
    font-size: 12px;
    color: #fff;
	border-radius: 8px;
	margin-left: 15px;
}
#todayforLive{
	background-color: #008CFF;
    padding: 5px 8px;
    font-size: 12px;
    color: #fff;
	border-radius: 8px;
	margin-left: 15px;
}
#todaylm2{
	background-color: #008CFF;
    font-size: 12px;
    color: #fff;
	border-radius: 8px;
	margin-left: 5px;
	padding: 4px 10px;
	margin-top: 19px;
}
.caret-right{
	font-size: 20px;
	margin-left: 10px;
}
.caret-rightforLive{
	font-size: 14px;
	margin-left: 10px;
}
#all_changes_saved_1m {
	position: absolute;
	left: 601px;
	top: 0px;
	overflow: visible;
	width: 147px;
	white-space: nowrap;
	line-height: 18px;
	margin-top: -3px;
	text-align: left;
	font-family: Averta-RegularItalic;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(147,177,209,1);
}
#Interaction_Board {
	/*position: absolute;
	left: 0px;
	top: 0px;*/
	float:left;
	overflow: visible;
	width: 200px;
	white-space: nowrap;
	text-align: left;
	font-family: Averta-Semibold;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	color: rgba(36,82,126,1);
	line-height: 22px;
	margin-top: 5px;
}
#Interaction_Boardlm2 {
	float:left;
	overflow: visible;
	white-space: nowrap;
	text-align: left;
	font-family: Averta-Semibold;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	color: rgba(36,82,126,1);
}
#TM {
	position: absolute;
	left: 182px;
	top: 0px;
	overflow: visible;
	width: 12px;
	white-space: nowrap;
	text-align: left;
	font-family: Averta-Semibold;
	font-style: normal;
	font-weight: normal;
	font-size: 8px;
	color: rgba(36,82,126,1);
}
#Side_Menu___Channels {
	/*position: absolute;*/
	width: 272px;
	/*height: 980px;*/
	height: 895px;
	/*left: 0px;
	top: 0px;*/
	overflow: visible;
	background-color:rgb(242, 247, 255);
	float:left;
}
#side_menu {
	/*position: absolute;
	width: 272px;
	height: 768px;
	left: 0px;
	top: 0px;
	overflow: visible;*/
}

#Group_2 {
	position: absolute;
	width: 193px;
	height: 22px;
	left: 23px;
	top: 711px;
	overflow: visible;
}
#ID1_Line_UI_Essential_cogwheel {
	position: absolute;
	width: 20px;
	height: 20px;
	left: 87px;
	top: 2px;
	overflow: visible;
}
.img_small_icon
{
	position:absolute;
	top:14px;
	left:14px;
}




#ID1_Line_Communication_call {
	position: absolute;
	width: 22px;
	height: 22px;
	left: 171px;
	top: 0px;
	overflow: visible;
}
#ID1_Line_Communication_call_ {
	position: absolute;
	width: 22px;
	height: 22px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Page_1_ {
	position: absolute;
	width: 22px;
	height: 22px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Group_13 {
	position: absolute;
	width: 20.49px;
	height: 20px;
	left: 0px;
	top: 2px;
	overflow: visible;
}
#Group {
	/*position: absolute;
	height: 39px;*/
	margin-left: 38px;
	margin-top: 27px;
	overflow: visible;
}
.fixedFooter{
	position: absolute;
	bottom: 20px;
	left:19px;
	width: 232px;
	height:143px;
}
#Nav___Tab___Establish_Copy {
	position: absolute;
	width: 198px;
	height: 39px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Establish {
	position: absolute;
	width: 193.842px;
	height: 21px;
	left: 1.158px;
	top: 12px;
	overflow: visible;
}
#Smart_Feed {
	position: absolute;
	left: 35.842px;
	top: 2px;
	overflow: visible;
	width: 90px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: rgba(0,140,255,1);
}
#Page_1_bm {
	position: absolute;
	width: 19.54px;
	height: 18.563px;
	left: 0px;
	top: 1.1px;
	overflow: visible;
}
#Group_6_bo {
	position: absolute;
	width: 19.54px;
	height: 18.563px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#Nav___Tab___Analyse {
	position: absolute;
	width: 194px;
	height: 39px;
	left: 38px;
	top: 190px;
	overflow: visible;
}
#Analyse {
	position: absolute;
	width: 194px;
	height: 40px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Analyze_Results {
	position: absolute;
	left: 37px;
	top: 4px;
	overflow: visible;
	width: 84px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: rgba(0,140,255,1);
	word-break:break-word;
}
.ico_accordion_Blue {
	position: absolute;
	width: 6px;
	height: 12px;
	left: 188px;
	top: 15px;
	overflow: visible;
}
.ico_accordion_Blue_Feed {
	position: absolute;
	width: 12px;
	height: 12px;
	left: 188px;
	top: 2px;
	overflow: visible;
}

#Nav___Ico_Analyse {
	position: absolute;
	width: 20px;
	height: 20px;
	left: 0px;
	top: 13px;
	overflow: visible;
}
#Page_1_bz {
	position: absolute;
	width: 20px;
	height: 20px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#Group_5 {
	position: absolute;
	width: 18.75px;
	height: 18.75px;
	left: 0.625px;
	top: 0.625px;
	overflow: visible;
}
#Live_Monitoring {
	/*position: absolute;
	width: 194px;
	height: 41px;
	left: 38px;
	top: 346px;
	overflow: visible;*/
}
#Live_Monitoring_b {
	position: absolute;
	width: 157px;
	height: 40px;
	left: 37px;
	top: 0px;
	overflow: visible;
}
#Live_Monitoring_ca {
	position: absolute;
	left: 0px;
	top: 0px;
	overflow: visible;
	width: 45px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: rgba(0,140,255,1);
}


#Icon___Live {
	position: absolute;
	width: 19px;
	height: 21px;
	left: 2px;
	top: 14px;
	overflow: visible;
}
#Page_1_ca {
	position: absolute;
	width: 18.162px;
	height: 20.723px;
	left: 0px;
	top: 0px;
	overflow: visible;
}



#Live_Monitoring_Copy {
	position: absolute;
	width: 190px;
	height: 40px;
	left: 41px;
	top: 443px;
	overflow: visible;
}
#Live_Monitoring_cf {
	position: absolute;
	width: 157px;
	height: 40px;
	left: 33px;
	top: 0px;
	overflow: visible;
}
#Content_Management {
	position: absolute;
	left: 0px;
	top: 0px;
	overflow: visible;
	width: 45px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: rgba(0,140,255,1);
}
#Icon___Live_cj {
	position: absolute;
	width: 19px;
	height: 19px;
	left: 0px;
	top: 13px;
	overflow: visible;
}



.line {
	position:absolute;
	top: 26em;
    left: 2em;
}
.line2 {
	position:absolute;
	top: 17em;
    left: 2em;
}

#_2019_Copyright_Blue {
	overflow: visible;
	width: 139px;
	white-space: nowrap;
	text-align: center;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	color: rgba(147,177,209,1);
	margin-left: 25px;
	margin-top:16px;
}
#Group_7 {
	position: absolute;
	width: 255px;
	height: 71.861px;
	left: 17px;
	top: 92.984px;
	overflow: visible;
}
/*#Nav___Active_Tab {
	position: absolute;
	width: 255px;
	height: 71.861px;
	left: 0px;
	top: 0px;
	overflow: visible;
	border:1px solid #fff;
}*/
.NavActive_Tab {
	/*position: absolute;
	width: 250px!important;
	height: 52px!important;
	left: 22px!important;
	top: 0px;
	overflow: visible;*/
	width: 255px;
	border:1px solid #fff;
	border-top-left-radius: 28px;
	border-bottom-left-radius: 28px;
	background-color:#fff;

	-webkit-box-shadow: 0px 5px 15px -12px rgba(0,140,255,0.35);
	-moz-box-shadow: 0px 5px 15px -12px rgba(0,140,255,0.35);
	box-shadow: 0px 5px 15px -12px rgba(0,140,255,0.35);
	height:60px;
	margin-left:-21px;
}
.half-circle {
    width: 200px;
    height: 100px;
    background-color: gold;
    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    border: 10px solid gray;
    border-bottom: 0;
}
.activeSubmenu
{
	/*border:1px solid;*/
	background: white;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
	height:60px;
	line-height: 44px;
}
.activeSmall_Tab {
    width: 40px!important;
    height: 40px!important;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 50%;
}
#white_select {
	position: absolute;
	width: 255px;
	height: 71.861px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Rectangle {
	opacity: 0.13;
}

#Nav___Tab___Create {
	position: absolute;
	width: 196px;
	height: 39px;
	left: 36px;
	top: 107px;
	overflow: visible;
}
#Create {
	position: absolute;
	width: 195px;
	height: 40px;
	left: 15px;
	top: 0px;
	overflow: visible;
}
#Create_Interactions {
	position: absolute;
	left: 38px;
	top: 4px;
	overflow: visible;
	width: 84px;
	/*white-space: nowrap;*/
	
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: rgba(0,140,255,1);
}
#Nav___Ico_Create {
	position: absolute;
	width: 22px;
	height: 22px;
	left: 0px;
	top: 12px;
	overflow: visible;
}
#Page_1_c_sd {
	/*position: absolute;
	width: 22px;
	height: 22px;
	left: 0px;
	top: 0px;
	overflow: visible;*/
	float: left;
	margin-top: 14px;
}
#Page_1_c {
	/*position: absolute;
	width: 22px;
	height: 22px;
	left: 0px;
	top: 0px;
	overflow: visible;*/
	float: left;
	margin-top: 10px;
}
#Page_1_c_a {
	float: left;
	margin-top: 13px;
}
#Page_1_c_b {
	float: left;
	margin-top: 24px;
	margin-left: 18px;
}
#Page_1_c_c {
	float: left;
	margin-top: 13px;
}
#Page_1_c_d {
	float: left;
	margin-top: 13px;
}

#Group_14 {
	position: absolute;
	width: 14.8px;
	height: 20.626px;
	left: 3.763px;
	top: 0.688px;
	overflow: visible;
}
/*#User {
	position: absolute;
	width: 224px;
	height: 60px;
	left: 20px;
	top: 770px;
	overflow: visible;
	border: 1px solid #58b3ff;
    border-radius: 28px;
}*/
#User {
	/*width: 224px;*/
	height: 60px;
	overflow: visible;
	border: 1px solid rgba(147, 177, 209, 0.3);
	border-radius: 28px;
	margin-top: 12px;
}

#Rectangle_Copy_5 {
	opacity: 0.3;
}

#John_Smith {
	position: absolute;
	left: 62px;
	top: 12px;
	overflow: hidden;
	width: 135px;
	height: 40px;
	line-height: 19px;
	margin-top: -1.6px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: rgba(0,140,255,1);
}
#Group_5_da {
	/*position: absolute;*/
	width: 38px;
	height: 36px;
	margin-left: 0px;
	margin-top: 12px;
	overflow: visible;
}
#Photo {
	/*position: absolute;
	left: 0px;
	top: 0px;*/
	width: 40px;
	height: 40px;
	overflow: visible;
	border-radius: 50%;
	margin-top: -4px;
}
#bl_logo___blue {
	/*position: absolute;*/
	width: 142px;
	height: 32px;
	margin-left: 39px;
	margin-top: 41px;
	overflow: visible;
}
#Group_10_dd {
	position: absolute;
	width: 141px;
	height: 32px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#BL_logo {
	position: absolute;
	width: 29.624px;
	height: 18.697px;
	left: 0px;
	top: 3px;
	overflow: visible;
}
#Vector {
	position: absolute;
	width: 29.624px;
	height: 18.697px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#path0_fill_link {
	position: absolute;
	width: 29.624px;
	height: 18.697px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#BlueLeap {
	position: absolute;
	left: 35px;
	top: 0px;
	overflow: visible;
	width: 94px;
	white-space: nowrap;
	text-align: left;
	font-family: Averta-Light;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	color: rgba(0,140,255,1);
	letter-spacing: -0.1923076629638672px;
}
#TM_dj {
	position: absolute;
	left: 134px;
	top: 7px;
	overflow: visible;
	width: 8px;
	white-space: nowrap;
	text-align: left;
	font-family: Averta-Semibold;
	font-style: normal;
	font-weight: normal;
	font-size: 5px;
	color: rgba(0,140,255,1);
	letter-spacing: -0.2px;
}
#Workflow {
	position: relative;
	width: 733px;
	height: 372px;
	margin-top: 7px;
	overflow: visible;
}
#WorkflowDiscover {
	position: absolute;
	width: 733px;
	margin-top: 7px;
	overflow: visible;
}
#workflow_illustration_v5 {
	position: relative;
	width: 740px;
	height: 372px;
	left: 0px;
	top: 0px;
	overflow: visible;
	background-image: url('../images/buttons/new_board.svg');
	border:2px solid transparent;
	background-repeat: no-repeat;
}
.resetPanelForBoard
{
    width: 740px;
	height: 370px;
	z-index: 1;
}
#workflow_illustration_v5_dn {
	position: absolute;
	width: 733px;
	height: 372px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Available_CRM {
	display: none;
	position: absolute;
	left: 20px;
	top: 25px;
	overflow: visible;
	width: 90px;
	white-space: nowrap;
	text-align: left;
	font-family: Averta-Semibold;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: rgba(36,82,126,1);
}
#Panel_4___Board {
	/*position: absolute;*/
	/*height: 980px;
	left: 1129px;*/
	height: 896px;
	float:right;
	width: 312px;
	top: 0px;
	/*overflow-y: auto;
	overflow-x: hidden;*/
	background-color:rgb(242, 247, 255);
}
#Panel_4___Connection_Progress {
	/*position: absolute;
	width: 310px;
	height: 980px;
	height: 905px;
	left: 1px;
	top: 0px;
	overflow: visible;*/
}

#Inspector {
	/*position: absolute;*/
	width: 284px;
	/*height: 386px;*/
	margin-left: 20px;
	/*margin-top: 20px;*/
	overflow: visible;
}
#InspectorClientChat {
	/*position: absolute;*/
	width: 284px;
	height: 386px;
	margin-left: 20px;
	margin-top: 12px;
	overflow: visible;
}
#InspectorChat {
	/*position: absolute;*/
	width: 289px;
	margin-left: 19px;
	/*margin-top: 74px;*/
	overflow: visible;
}
#WhatsApp_number {
	position: absolute;
	left: 1px;
	top: 120px;
	overflow: visible;
	width: 150px;
	line-height: 18px;
	margin-top: -3px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(147,177,209,1);
}
#Studio_Flow_SID {
	/*position: absolute;
	left: 1px;
	top: 40px;
	overflow: visible;
	width: 220px;
	line-height: 18px;
	margin-top: -3px;*/
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(147,177,209,1);
    margin-top: 16px;
}
#Chat_Service_Instanc {
	position: absolute;
	left: 1px;
	top: 160px;
	overflow: visible;
	width: 200px;
	line-height: 18px;
	margin-top: -3px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(147,177,209,1);
}
#Account_SID {
	position: absolute;
	left: 1px;
	top: 200px;
	overflow: visible;
	width: 202px;
	line-height: 18px;
	margin-top: -3px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(147,177,209,1);
}
#Auth_Token {
	position: absolute;
	left: 1px;
	top: 323px;
	overflow: visible;
	width: 150px;
	line-height: 18px;
	margin-top: -3px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(147,177,209,1);
}
#Whatsapp_1 {
	/*position: absolute;
	left: 0px;
	top: 2px;
	overflow: visible;
	width: 160px;*/
	text-align: left;
	font-family: Averta-Semibold;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	color: rgba(36,82,126,1);
}
#Descriptor {
	/*position: absolute;
	width: 270px;
	height: 179px;
	left: 20px;
	top: 108px;
	overflow: visible;
	background-color: #fff;
	border-radius: 10px;
	color:#105f89;
	padding: 8px;*/
	overflow-y: auto;
    width: 253px;
    height: 165px;
    padding: 8px;
}
.descriptor_outer
{
	/*position: absolute;*/
	width: 270px;
	height: 179px;
	margin-left: 20px;
	margin-top: 10px;
	overflow: visible;
	background-color: #fff;
	border-radius: 10px;
	color:#105f89;
	padding: 8px;
}
#createBoard {
	/*position: absolute;*/
	margin-left: 20px;
	margin-top: 24px;
}

#P4___Dont_Show {
	position: absolute;
	width: 236px;
	height: 16px;
	left: 20px;
	top: 145px;
	overflow: visible;
}
#Group_5_ed {
	position: absolute;
	width: 236px;
	height: 16px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Don_t_show_again {
	position: absolute;
	left: 24px;
	top: 3px;
	overflow: hidden;
	width: 213px;
	height: 10px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	color: rgba(147,177,209,1);
}

#A_connection_is_made {
	position: absolute;
	left: 20px;
	top: 9px;
	overflow: visible;
	width: 209px;
	height: 132px;
	line-height: 18px;
	margin-top: -3px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(16,95,137,1);
}
#ID1_5_steps_done {
	position: absolute;
	left: 20px;
	top: 87px;
	overflow: visible;
	width: 80px;
	white-space: nowrap;
	text-align: left;
	font-family: Averta-Semibold;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(147,177,209,1);
}
#Icon___Progress {
	position: absolute;
	width: 270px;
	height: 3px;
	left: 20px;
	top: 60px;
	overflow: visible;
}
#progress {
	position: absolute;
	width: 270px;
	height: 3px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#Connection_Progress {
	/*position: absolute;*/
	margin-left: 19px;
	margin-top: 47px;
	overflow: visible;
	width: 274px;
	white-space: nowrap;
	text-align: left;
	font-family: Averta-Semibold;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	color: rgba(36,82,126,1);
}

#Inventory {
	position: absolute;
	width: 120px;
	height: 44px;
	left: 940px;
	top: 812px;
	overflow: visible;
}
#Group_3 {
	position: absolute;
	width: 120px;
	height: 44px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Button___Secondary {
	position: absolute;
	width: 120px;
	height: 44px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Group_Copy {
	position: absolute;
	width: 100px;
	height: 31.68px;
	left: 10px;
	top: 0.88px;
	overflow: visible;
}
#Rectangle_eu {
	opacity: 0.3;
}

#Add_New {
	position: absolute;
	left: 26px;
	top: 3.457px;
	overflow: visible;
	width: 68px;
	height: 22px;
	line-height: 22px;
	margin-top: -5px;
	text-align: center;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(36,82,126,1);
}
#Group_2_ew {
	position: absolute;
	width: 16px;
	height: 16px;
	left: 10px;
	top: 7.84px;
	overflow: visible;
}
#grid_inventory_2_0 {
	/*position: absolute;*/
	width: 738px;
	/*height: 372px;*/
	height:426px;
	/*left: 330px;*/
	margin-top: 23px;
	overflow: visible;
	background-color: #f2f7ff;
	border-radius: 6px;
}
#Group_6_e {
	width: 738px;
	height: 398px;
	/*left: 20px;
	top: 20px;
	overflow: visible;
	position: absolute;
	*/
}
#block_grid {
	/*position: absolute;*/
	width: 713px;
	height: 380px;
	margin-left: 18px;/**/
	margin-top: 16px;
	/*overflow-y: auto;*/
	font-size:14px;
	color:#24527e;
	/*overflow-x: hidden;*/
}
.gridHeaderTextAlign{
	margin-left: 0px!important;
	padding-top: 11px!important;
	width: 740px;
}
#Messenger {
	position: absolute;
	width: 88px;
	height: 68px;
	left: 118px;
	top: 10px;
	overflow: visible;
}
#messenger {
	position: absolute;
	width: 40px;
	height: 39px;
	left: 15px;
	top: 4px;
	overflow: visible;
}

#Instagram {
	position: absolute;
	width: 62px;
	height: 68px;
	left: 1px;
	top: 118px;
	overflow: visible;
}
#instagram {
	position: absolute;
	width: 36px;
	height: 36px;
	left: 15px;
	top: 0px;
	overflow: visible;
}
#XMLID_28_ {
	position: absolute;
	width: 36px;
	height: 36px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#AI {
	position: absolute;
	width: 183px;
	height: 198px;
	left: 242px;
	top: 0px;
	overflow: visible;
}
#Group_16_Copy_5 {
	position: absolute;
	width: 37.999px;
	height: 51.805px;
	left: 0px;
	top: 108px;
	overflow: visible;
}
#Group_20 {
	position: absolute;
	width: 37.999px;
	height: 51.805px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Group_16 {
	position: absolute;
	width: 37.936px;
	height: 51.805px;
	left: 0.064px;
	top: 0px;
	overflow: visible;
}

#Group_7_fp {
	position: absolute;
	width: 29.941px;
	height: 47.124px;
	left: 7.995px;
	top: 4.681px;
	overflow: visible;
}

#Group_7_fr {
	position: absolute;
	width: 29.941px;
	height: 47.124px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#Group_19 {
	position: absolute;
	width: 32.854px;
	height: 47.518px;
	left: 0px;
	top: 0.693px;
	overflow: visible;
}

#Group_19_f {
	position: absolute;
	width: 32.854px;
	height: 47.518px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Group_9_Copy {
	position: absolute;
	width: 37.999px;
	height: 51.805px;
	left: 133px;
	top: 108px;
	overflow: visible;
}
#Group_21 {
	position: absolute;
	width: 37.999px;
	height: 51.805px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Group_8 {
	position: absolute;
	width: 37.935px;
	height: 51.805px;
	left: 0.064px;
	top: 0px;
	overflow: visible;
}

#Group_7_f {
	position: absolute;
	width: 29.941px;
	height: 47.124px;
	left: 7.994px;
	top: 4.681px;
	overflow: visible;
}

#Group_7_gb {
	position: absolute;
	width: 29.941px;
	height: 47.124px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Group_11 {
	position: absolute;
	width: 32.854px;
	height: 47.518px;
	left: 0px;
	top: 0.693px;
	overflow: visible;
}

#Group_11_gg {
	position: absolute;
	width: 32.854px;
	height: 47.518px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#Group_20_gm {
	position: absolute;
	width: 16.424px;
	height: 14.17px;
	left: 15.43px;
	top: 16.332px;
	overflow: visible;
}

#Group_6_Copy {
	position: absolute;
	width: 37.998px;
	height: 51.805px;
	left: 133px;
	top: 0px;
	overflow: visible;
}
#Group_21_gr {
	position: absolute;
	width: 37.998px;
	height: 51.805px;
	left: 0px;
	top: 0px;
	overflow: visible;
}


#Group_5_gu {
	position: absolute;
	width: 11.661px;
	height: 5.376px;
	left: 24.732px;
	top: 0px;
	overflow: visible;
}

#Group_5_gw {
	position: absolute;
	width: 11.661px;
	height: 5.376px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Group_8_gz {
	position: absolute;
	width: 29.941px;
	height: 47.124px;
	left: 7.995px;
	top: 4.681px;
	overflow: visible;
}

#Group_8_g {
	position: absolute;
	width: 29.941px;
	height: 47.124px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Group_12 {
	position: absolute;
	width: 44.852px;
	height: 47.518px;
	left: 0px;
	top: 0.693px;
	overflow: visible;
}

#Group_12_g {
	font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(36,82,126,1);
}

#Group_20_hd {
	position: absolute;
	width: 7.251px;
	height: 11.769px;
	left: 24.087px;
	top: 19.461px;
	overflow: visible;
}
#Group_5_hn {
	position: absolute;
	width: 11.661px;
	height: 5.376px;
	left: 24.732px;
	top: 0px;
	overflow: visible;
}

#Group_5_hp {
	position: absolute;
	width: 11.661px;
	height: 5.376px;
	left: 0px;
	top: 0px;
	overflow: visible;
}


#Group_23 {
	position: absolute;
	width:45px;
	height: 22px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#Twitter {
	position: absolute;
	width: 43px;
	height: 68px;
	left: 131px;
	top: 118px;
	overflow: visible;
}

#Twitter_Bird {
	position: absolute;
	width: 42px;
	height: 36px;
	left: 1px;
	top: 0px;
	overflow: visible;
}

#Snapchat {
	position: absolute;
	width: 62.5px;
	height: 72px;
	left: 481.5px;
	top: 6px;
	overflow: visible;
}
#Salesforce {
	position: absolute;
	left: 0px;
	top: 53px;
	overflow: visible;
	width: 63px;
	white-space: nowrap;
	text-align: center;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: rgba(36,82,126,1);
}
#salesforca_seeklogo_com {
	position: absolute;
	width: 62px;
	height: 44px;
	left: 0.5px;
	top: 0px;
	overflow: visible;
}

#Zendesk_logo {
	position: absolute;
	width: 37px;
	height: 28px;
	left: 614px;
	top: 16px;
	overflow: visible;
}
#Zendesk {
	position: absolute;
	left: 606.5px;
	top: 59px;
	overflow: visible;
	width: 53px;
	white-space: nowrap;
	text-align: center;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: rgba(36,82,126,1);
}
#Group_2_ir {
	position: absolute;
	width: 60px;
	height: 88.725px;
	left: 484px;
	top: 116.275px;
	overflow: visible;
}

#Microsoft_Dynamics {
	position: absolute;
	left: 0px;
	top: 0px;
	overflow: visible;
	width: 61px;
	white-space: nowrap;
	text-align: center;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: rgba(36,82,126,1);
}
#Microsoft_Dynamics_iu {
	position: absolute;
	width: 45.77px;
	height: 37.156px;
	left: 6px;
	top: 0px;
	overflow: visible;
}
#Group_iv {
	position: absolute;
	width: 45.77px;
	height: 37.156px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#Group_i {
	position: absolute;
	width: 2.501px;
	height: 1.321px;
	left: 43.269px;
	top: 35.723px;
	overflow: visible;
}

#Group_9_i {
	position: absolute;
	width: 69px;
	height: 49px;
	left: 601px;
	top: 137px;
	overflow: visible;
}
#Group_4_Copy {
	position: absolute;
	width: 40px;
	height: 19px;
	left: 12.5px;
	top: 30px;
	overflow: visible;
}
#Oracle {
	position: absolute;
	left: 0px;
	top: 0px;
	overflow: visible;
	width: 41px;
	white-space: nowrap;
	text-align: center;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: rgba(36,82,126,1);
}
#Oracle_logo {
	position: absolute;
	width: 69px;
	height: 9px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#Whatsapp {
	position: absolute;
	width: 62px;
	height: 68px;
	left: 0px;
	top: 10px;
	overflow: visible;
}
#whatsapp {
	position: absolute;
	width: 36px;
	height: 36px;
	left: 16px;
	top: 0px;
	overflow: visible;
}
#Whatsapp_jf {
	position: absolute;
	left: 0px;
	top: 49px;
	overflow: visible;
	width: 63px;
	white-space: nowrap;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: rgba(36,82,126,1);
}
#Channels {
	/*position: absolute;
	left: 0px;
	top: 0px;
	overflow: visible;*/
	width: 49px;
	white-space: nowrap;
	line-height: 18px;
	margin-top: -3px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(147,177,209,1);
}
#AI_Services {
	/*position: absolute;
	left: 241px;
	top: 0px;
	overflow: visible;*/
	width: 58px;
	white-space: nowrap;
	line-height: 18px;
	margin-top: -3px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(147,177,209,1);
}
#CRM {
	/*position: absolute;
	left: 481px;
	top: 0px;
	overflow: visible;*/
	width: 26px;
	white-space: nowrap;
	line-height: 18px;
	margin-top: -3px;
	text-align: left;
	font-family: Averta-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(147,177,209,1);
}


#Group_3_jm {
	position: absolute;
	width: 230px;
	height: 25px;
	left: 19px;
	top: 9px;
	overflow: visible;
}
#Inventory_jn {
	position: absolute;
	left: 0px;
	top: 0px;
	overflow: hidden;
	width: 231px;
	height: 25px;
	text-align: left;
	font-family: Averta-Semibold;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	color: rgba(36,82,126,1);
}
#Please_select_the_co {
	position: absolute;
	left: 19px;
	top: 43px;
	overflow: visible;
	width: 219px;
	white-space: nowrap;
	line-height: 18px;
	margin-top: -3px;
	text-align: left;
	font-family: Averta-RegularItalic;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: rgba(152,187,215,1);
}
#Button___Secondary_jp {
	position: absolute;
	width: 158px;
	height: 36px;
	left: 562px;
	top: 24px;
	overflow: visible;
}
#Button___Secondary_jq {
	position: absolute;
	width: 157.071px;
	height: 36px;
	left: 0.62px;
	top: 0px;
	overflow: visible;
}
#Group_Copy_jr {
	position: absolute;
	width: 157.071px;
	height: 36px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#Icon___Tick {
	position: absolute;
	width: 18px;
	height: 18px;
	left: 422px;
	top: 646px;
	overflow: visible;
}
#Icon___Tick_jz {
	position: absolute;
	width: 18px;
	height: 18px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Icon___Tick_j {
	position: absolute;
	width: 18px;
	height: 18px;
	left: 666px;
	top: 646px;
	overflow: visible;
}
#Icon___Tick_ka {
	position: absolute;
	width: 18px;
	height: 18px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

#Icon___Tick_kb {
	position: absolute;
	width: 18px;
	height: 18px;
	left: 910px;
	top: 646px;
	overflow: visible;
}
#Icon___Tick_kc {
	position: absolute;
	width: 18px;
	height: 18px;
	left: 0px;
	top: 0px;
	overflow: visible;
}

.button {
    /*background-color: #fff;
    border: 0.5px solid #ccc;
	color: rgb(36, 82, 126);*/
	background-color: #008CFF;
    border: 0.5px solid #008CFF;
    color: #fff;
	padding: 9px 22px;
	/*padding: 9px 12px;*/
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 30px;
    outline: none;
	font-family: "Averta-Semibold";
	width:120px;
	height:36px;
}
.button:hover
{
	background-color: #0070CC;
}
.button:focus,.button:active {
	outline: none !important;
	box-shadow: none;
 }
 .button_secondary {
	background-color: #FFF;
    border: 1px solid #008CFF;
    color: #008CFF;
	padding: 9px 22px;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 30px;
    outline: none;
	font-family: "Averta-Semibold";
	height:36px;
}
.button_secondary :hover
{
	border: 1px solid #0070CC;
}
.button_secondary:focus,.button_secondary:active {
	outline: none !important;
	box-shadow: none;
 }
 .button_secondary[disabled] {
	border: 1px solid #a9d7ff;
	color:#a9d7ff;
	background-color: #fff;
 }
 .button_secondary[disabled]:hover {
	border: 1px solid #a9d7ff;
	color:#a9d7ff;
	background-color: #fff;
 }

 /*---------BUTTON TERNIARNY-----------*/
 .button_tertiary {
	background-color: transparent;
    border: 1px solid transparent;
	color: #008CFF;
	padding: 0px 22px;
    display: inline-block;
    font-size: 12px;
    /*margin: 4px 2px;*/
    cursor: pointer;
    outline: none;
	font-family: "Averta-Semibold";
	text-decoration: underline;
}
.button_tertiary :hover
{
	color: #0070CC;
}
.button_tertiary:focus,.button_tertiary:active {
	outline: none !important;
	box-shadow: none;
 }
 .button_tertiary[disabled] {
	background-color: transparent;
    border: 1px solid transparent;
	color:#a9d7ff;
 }
 .button_tertiary[disabled]:hover {
	border: 1px solid transparent;
	color:#a9d7ff;
	background-color: transparent;
 }
 .button_icon_edit
 {
	background-image: url('../images/buttons/edit.svg');
	background-repeat: no-repeat;
	margin-left: 20px;
	padding-left: 20px;
	cursor: pointer;
	background-color: transparent;
 }
 .button_icon_edit:focus,.button_icon_edit:active {
	outline: none !important;
	box-shadow: none;
 }
 .button_icon_create
 {
	background-image: url('../images/buttons/create.svg');
	background-repeat: no-repeat;
	margin-left: 20px;
	padding-left: 20px;
	cursor: pointer;
	background-color: transparent;
 }
 .button_icon_create:focus,.button_icon_create:active {
	outline: none !important;
	box-shadow: none;
 }
 .button_icon_test
 {
	background-image: url('../images/buttons/test.svg');
	background-repeat: no-repeat;
	margin-left: 5px;
	padding-left: 20px;
	cursor: pointer;
	background-color: transparent;
 }
 .button_icon_test:focus,.button_icon_test:active {
	outline: none !important;
	box-shadow: none;
 }
 /*-----------------------------------*/
.small_button_disable
{
	background-color: #fff;
    border: 1px solid #f44336;
    color: #ff5736;
    padding: 4px 8px;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    border-radius: 30px;
    outline: none;
    font-family: "Averta-Regular";
}
.small_button_disable:focus,.small_button_disable:active
{
	outline: none !important;
	box-shadow: none;
}
.small_button_disable_round
{
	background-color: #fff;
    border: 1px solid #f44336;
    color: #ff5736;
    padding: 4px 8px;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    border-radius: 50%;
    outline: none;
	font-family: "Averta-Regular";
	margin-left: 10px;
}
.small_button_enable
{
	background-color: #fff;
    border: 1px solid green;
    color: green;
    padding: 4px 8px;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    border-radius: 30px;
    outline: none;
    font-family: "Averta-Regular";
}
.small_button_enable:focus,.small_button_enable:active
{
	outline: none !important;
	box-shadow: none;
}
.small_button_enable:focus,.small_button_enable:active
{
	outline: none !important;
	box-shadow: none;
}
input[type=text] ,input[type=password], input[type=file], textarea,select{
  color:#24527e;
  padding:6px 12px;
  /*border: 1px solid #a9d7ff;*/
  border: 1px solid transparent;
  border-radius: 30px;
  outline:none;
}
select
{
	-moz-appearance: none;
	-webkit-appearance: none;
	background:#ffffff url("../images/buttons/select-arrow.svg") no-repeat center right;
	background-position: 159px 12px;
}
select.arrowAlignForPanel3
{
	-moz-appearance: none;
	-webkit-appearance: none;
	background:#ffffff url("../images/buttons/select-arrow.svg") no-repeat center right;
	background-position: 235px 12px;
}
select.arrowAlignForw200
{
	-moz-appearance: none;
	-webkit-appearance: none;
	background:#ffffff url("../images/buttons/select-arrow.svg") no-repeat center right;
	background-position: 170px 12px;
}
select.arrowAlignForw139
{
	-moz-appearance: none;
	-webkit-appearance: none;
	background:#ffffff url("../images/buttons/select-arrow.svg") no-repeat center right;
	background-position: 110px 15px;
	color: #008CFF;
}
select.arrowAlignForw120
{
	-moz-appearance: none;
	-webkit-appearance: none;
	background:#ffffff url("../images/buttons/db_select_arrow.svg") no-repeat center right;
	background-position: 99px 13px;
}
select.arrowAlignForw330
{
	-moz-appearance: none;
	-webkit-appearance: none;
	background:#ffffff url("../images/buttons/select-arrow.svg") no-repeat center right;
	background-position: 300px 14px;
}
select.arrowAlignForw355
{
	-moz-appearance: none;
	-webkit-appearance: none;
	background:#ffffff url("../images/buttons/select-arrow.svg") no-repeat center right;
	background-position: 322px 14px;
}
select.arrowAlignForw101
{
	-moz-appearance: none;
	-webkit-appearance: none;
	background:#ffffff url("../images/buttons/select-arrow.svg") no-repeat center right;
	background-position: 76px 12px;
	color: #008CFF;
}
textarea{
	color:#24527e;
	padding:6px 12px;
	border: 1px solid #a9d7ff;
	border-radius: 10px;
	outline:none;
  }
input:read-only {
  background-color: #e3efff;
}
textarea:read-only {
	background-color: #e3efff;
  }
.progress
{
	height:3px;
}
.v1
{
	border-left: 1px solid #d8d8d8;
    position: absolute;
    /*z-index: 1;*/
    opacity: 1;
    left: 233px;
    min-height: 290px;
	height: auto;
	display:none;
}
.v2
{
	border-left: 1px solid #D8D8D8;
    position: absolute;
    /*z-index: 1;*/
    opacity: 1;
    left: 476px;
    min-height: 290px;
	height: auto;
	display:none;
}
/*ADMIN STORE*/
#all_changes_saved_1m {
		position: absolute;
		left: 861px;
		top: 0px;
		overflow: visible;
		width: 147px;
		white-space: nowrap;
		line-height: 18px;
		margin-top: -3px;
		text-align: left;
		font-family: Averta-RegularItalic;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(147,177,209,1);
	}
	#BlueLeap_Store_Setup {
		position: absolute;
		left: 0px;
		top: 0px;
		overflow: visible;
		width: 207px;
		white-space: nowrap;
		text-align: left;
		font-family: Averta-Semibold;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(36,82,126,1);
	}
.cart_item
{
	color:#24527E;
}
#Cart {
		position: absolute;
		width: 59px;
		height: 34px;
		left: 1114px;
		top: 37.5px;
		overflow: visible;
		border: 1px solid #58b3ff;
		border-radius: 28px;
		padding: 6px;
	}
.background_channel{
    width: 1010px;
    height: 980px;
    clear: both;
    margin-left: 333px;
    margin-top: 5em;
    background-color: rgb(242, 247, 255);
	}
.autoScroll
{
	height:250px;
	overflow-y:auto;
	scrollbar-color: rgba(0, 140, 255, 0.18) #aaa0;
	scrollbar-width: thin;
}
.notification-success
{
  background-color: #008CFF !important;
}
/*CREATE USER CSS*/
#Admin___Create_User {
	/*position: absolute;
	box-sizing: border-box;
	background: #E5E5E5;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_45 {
	position: absolute;
	width: 1048px;
	height: 895px;
	margin-top: 29px;
	overflow: visible;
	padding-top: 10px;
	background-color: #f2f7ff;
	border-radius: 6px;
}
.tabset > input[type="radio"] {
	position: absolute;
	left: -200vw;
  }
  
  .tabset .tab-panel {
	display: none;
  }
  
  .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
  .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
  .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
  .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
  .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
  .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
	display: block;
  }
  
  
  
  .tabset > label {
	position: relative;
	display: inline-block;
	/*padding: 15px 40px 15px 40px;*/
	border: 10px 30px 0px 30px;
	margin: 0px;
	left:30px;
	border-radius: 12px 12px 0px 0px;
	cursor: pointer;
	font-weight: normal;
	text-align: center;
	font-size: 16px;
    color: #93b1d1;
  }
  .tabDeactive
  {
	padding: 15px 40px 20px 40px;
    /*height: 54px;*/
	background: #F8FBFF;
	top:10px;
	z-index: 9;
	/*box-shadow: 0px 12px 34px rgba(0, 140, 255, 0.1);*/
	box-shadow: -8px 5px 12px -15px rgba(0,140,255,0.75);
  }
  .tabset > label::after {
	content: "";
	position: absolute;
	left: 15px;
	width: 22px;
	height: 4px;
	/*background: #8d8d8d;*/
  }
  
  .tabset > label:hover,
  .tabset > input:focus + label {
	color: #24527e;
  }
  .tabset > input:checked + label {
	color: #24527e;
	font-size: 18px;
	background-color: #fff;
	margin-bottom: unset;
	top:5px;
	font-weight:600;
	box-shadow: none;
	padding: 15px 40px 15px 40px;
  }
  .tab-panel {
	padding: 30px! important;
	margin: 0px 30px 30px 30px;
	background-color: #fff;
    /*width: 100.8em;*/
  }
  *,
  *:before,
  *:after {
	box-sizing: border-box;
  }
  .tabset {
	/*max-width: 65em;*/
	margin-top:14px;
  }
  .tab-panel
  {
	height:697px;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
  }
  #tabForm
  {
	position: absolute;
    width: 284px;
    height: 386px;
    left: 19px;
    top: 65px;
    overflow: visible;
  }
  /*SENTIMENT*/
  #Smart_Feed___RobotCleaner {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_46 {
	/*position: absolute;*/
	width: 1048px;
	height: 895px;
	margin-top: 4px;
	overflow: visible;
	background-color: #f2f7ff;
}
.divHeaderTxt
{
	/*margin-top: 10px;
	padding-left:10px;*/
	float:left;
	margin-top: 15px;
    margin-left: 25px;
}
#SentimentHeaderTxt
{
    text-align: left;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
	color: rgba(36,82,126,1);
	
}
#VersionHeader
{
    text-align: left;
    font-family: Averta-Light;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
	color: rgba(0,140,255,1);
	
}
#VersionNumber
{
    text-align: left;
    font-family: Averta-Light;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
	color: rgba(36,82,126,1);
	
}
#profilePopText
{
    text-align: center;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
	color: rgba(36,82,126,1);
	
}
#SentimentSubHeaderTxt
{
	text-align: left;
    font-family: Averta-Regular;
    font-weight: bold;
    font-size: 12px;
	color: #93b1d1;
}
#SentimentSubHeaderTxtContent
{
	text-align: center;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
	color: #93b1d1;
}
#Sender
{
	text-align: left;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
	color: #93b1d1;
}
.imgHeaderIcon
{
	/*float:left;*/
	margin-top: 10px;
	height:40px;
	width:40px;
	border-radius: 50%;
}
.imgHeaderIconContent2
{
	float:left;
}
.imgHeaderIcon_sentiment
{
	float:left;
	margin-top: 10px;
	border-radius: 50%;
}
.imgHeaderIcon_sentiment_social
{
	float:left;
	margin-top: 10px;
}
.imgHeaderIcon_sentiment_social_blGirl
{
	float:left;
	margin-top: 10px;
	height:40px;
	width:40px;
}
.fixWidthWithOverflow
{
	height:500px;
	overflow-y:scroll!important;
}
.imagePost
{
	background-color: #fff;
	border-radius: 8px;
	clear: both;
	margin-top: 70px;
	height:450px;
}
.smallPost
{
	background-color: #fff;
	border-radius: 8px;
	clear: both;
	margin-top: 20px;
	height:170px;
}
.ImgPostIcon
{
	margin:12px;
}

.ImgPostText{
	padding: 12px 22px;
    font-size: 14px;
    color: #24527e;
    font-family: Averta-Regular;
}
.ImgSmallPostText
{
	text-align: left;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
	color: rgba(36,82,126,1);
	padding: 0px 20px;
}
/*LIVE MONITORING*/
#Live_monitoring {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_47 {
	/*position: absolute;*/
	width: 1048px;
	height: 896px;
	margin-top: 4px;
	overflow: visible;
	background-color: #f2f7ff;
	border-radius: 6px;
}
#Group_2_A1_Group_47_Ar {
	/*position: absolute;*/
	width: 1110px;
	height: 896px;
	margin-top: 4px;
	overflow: visible;
	background-color: #f2f7ff;
	border-radius: 6px;
}
/*FASHION*/
/*#Smart_Feed___fashion {
	position: absolute;
	width: 1440px;
	height: 895px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;
}*/
#Group_2_A1_Group_48 {
	position: absolute;
	width: 1048px;
	height: 980px;
	left: 331px;
	top: 84px;
	overflow: visible;
	background-color: #f2f7ff;
}
/*CUSTOM TRACKER*/
#Smart_Feed___Customtracker {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_49 {
	/*position: absolute;*/
	width: 1048px;
	height: 895px;
	margin-top: 10px;
	overflow: visible;
	background-color: #f2f7ff;
}

.lblHeaderTxt
{
	font-size: 14px;
	color:#24527e;
	float: left;
	margin-left: 10px;
}
.lblHeaderTxt_left
{
	font-size: 14px;
	color:#24527e;
	text-align: left;
	padding-top: 55px;
    padding-left: 20px;
}
.lblHeaderTxt_left_2
{
	font-size: 14px;
	color:#24527e;
	text-align: left;
    padding-left: 20px;
}
.lblHeaderTxt_left_3
{
	font-size: 14px;
	color:#24527e;
}
.labelText_left
{
	font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(147,177,209,1);
    padding-left: 20px;
}
.labelText_left_2
{
	font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(147,177,209,1);
}
.img_top_img
{
	height:24px;
	width:24px;
	float: left;
}
.img_emoji
{
	height:50px;
	width:50px;
}
.percent_emoji
{
	font-size: 18px;
	color: #f44336;
}

/*Trending*/
#Smart_Feed___Trending {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_50 {
	/*position: absolute;*/
	width: 1048px;
	height: 895px;
	margin-top: 10px;
	overflow: visible;
	background-color: #f2f7ff;
}
/*ANALYZE RESULTS*/
#Smart_Feed___Results {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_51 {
	/*position: absolute;*/
	width: 1048px;
	height: 895px;
	left: 331px;
	top: 4px;
	overflow: visible;
	background-color: #f2f7ff;
}
.percent_Result
{
	font-size: 30px;
	color: #24527e;
}
.percent_Result_2
{
	font-size: 20px;
	color: #24527e;
}
/*CONTENT*/
#Smart_Feed___Content {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_52 {
	position: absolute;
	width: 1048px;
	height: 980px;
	left: 331px;
	top: 84px;
	overflow: visible;
	background-color: #f2f7ff;
}
.content_bg
{
	background-image: url(../images/content_bg.png);
	background-repeat: no-repeat;
    height: 650px;
    width: 978px!important;
	margin-left: 33px;
	background-size: 100% 100%;
}
#ContentHeaderTxt
{
    text-align: left;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
	color: rgba(36,82,126,1);
	margin-top: 20px;
	margin-left: 18px;
	text-decoration: underline;
}
#ContentHeaderTxt2
{
    text-align: left;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
	color: #008CFF;
	margin-top: 20px;
	margin-left: -19px;
}
#ContentHeaderTxt3
{
    text-align: left;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
	color: #008CFF;
	margin-top: 20px;
	margin-left: -123px;
}
.border-left{
	border-left: 1px solid #008cff;
}
/*CONTENT2*/
#Smart_Feed___Content2 {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_53 {
	position: absolute;
	width: 1048px;
	height: 980px;
	left: 331px;
	top: 84px;
	overflow: visible;
	background-color: #f2f7ff;
}
/*CONTENT3*/
#Smart_Feed___Content3 {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_54 {
	position: absolute;
	width: 1048px;
	height: 980px;
	left: 331px;
	top: 84px;
	overflow: visible;
	background-color: #f2f7ff;
}
/*PROFILE*/
#Smart_Feed___Profile {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_55 {
	/*position: absolute;*/
	width: 1048px;
	height: 895px;
	margin-top: 4px;
	overflow: visible;
	background-color: #f2f7ff;
	border-radius: 6px;
}
#rauchbier{
	background-color: #fff !important;
	z-index: 10;
    position: relative;
}
#marzen{
	background-color: #fff !important;
	z-index: 10;
    position: relative;
}
#org{
	background-color: #fff !important;
	z-index: 10;
    position: relative;
}
#settings
{
	background-color: #fff !important;
	z-index: 10;
    position: relative;
}
/*CUSTOM TRACKER LOADING*/
#Smart_Feed___CustomtrackerLoading {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_56 {
	/*position: absolute;*/
	width: 1048px;
	height: 895px;
	margin-top: 10px;
	overflow: visible;
	background-color: #f2f7ff;
}
/*NOT FOUND*/
/*#not_found {
	position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;
}*/
#Group_2_A1_Group_57 {
	position: absolute;
	width: 1048px;
	height: 980px;
	left: 331px;
	top: 84px;
	overflow: visible;
	background-color: #f2f7ff;
}
/*LIVE MONITORING 2*/
#Live_monitoring2 {
	/*position: absolute;
	width: 1440px;
	height: 980px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;*/
}
#Group_2_A1_Group_58 {
	position: absolute;
	width: 1048px;
	height: 980px;
	left: 331px;
	top: 84px;
	overflow: visible;
	background-color: #f2f7ff;
}


/*SCROLLBAR DESIGN*/
/* width */
::-webkit-scrollbar {
  width: 8px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #ebf3ff; 
  border-radius: 70px 70px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1eaff;
  border-radius: 70px 70px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a9d7ff; 
}

.tab-panelforLiveMonitoring
  {
	height:170px!important;
}
.sidemenuIcon
{
	margin-left:1px;
}
.NavActive_Tab .sidemenuIcon
{
	margin-left:20px;
}

/*PBB*/
#Workflow_pbb {
	position: relative;
	width: 740px;
	height: 372px;
	margin-top: 7px;
	overflow: visible;
	margin: 0 auto;
}

#workflow_illustration_v5_pbb {
	position: relative;
	width: 740px;
	height: 372px;
	left: 0px;
	top: 0px;
	overflow: visible;
	background-image: url('../images/buttons/new_board.svg');
	border:2px solid transparent;
	background-repeat: no-repeat;
}
select.arrowAlignForPhone
{
	-moz-appearance: none;
	-webkit-appearance: none;
	background:#ffffff url("../images/buttons/select-arrow.svg") no-repeat center right;
	background-position: 234px 12px;
	color: #24527E;
}