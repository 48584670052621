.type_a
{
  cursor: pointer;
}
/*INTERACTION MOVEMENT*/
.item0 {
  position: absolute;
  top: 48%;
  left: 26%;
  cursor: pointer;
  z-index: 2;
}
.item1 {
  position: absolute;
  top: 25.5%;
  left: 45%;
  cursor: pointer;
  z-index: 2;
}
.item2 {
  position: absolute;
  top: 0%;
  left: 68%;
  cursor: pointer;
  z-index: 2;
}

.item3 {
  position: absolute;
  top: 58%;
  left: 35%;
  cursor: pointer;
  z-index: 2;
}

/*----TEXT ----*/
.iconName0
{
    min-width: 75px;
    padding: 5px 7px;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 25%;
    /*top: 77%;
    left: 43%;*/
    text-align: center;
    transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0,0);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: Averta-Regular;
    color: #0081c4;
    font-size: 8px;
}
.iconName1
{
    min-width: 75px;
    padding: 5px 7px;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 25%;
    text-align: center;
    transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0,0);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: Averta-Regular;
    color: #0081c4;
    font-size: 8px;
}
.iconName2
{
    min-width: 75px;
    padding: 5px 7px;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 25%;
    text-align: center;
    transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0,0);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: Averta-Regular;
    color: #0081c4;
    font-size: 8px;
}
.inventoryImg
{
  height:80px;
}
/*-------------*/
/*----TEXT LEFT BORDER---*/
.TextLeftBorder0
{
  background: #08c7ff;
  position: absolute;
  top: 1px;
  left: -2px;
  height:100%;
  width: 2px;
  transform: rotate(0deg) skewY(-45deg);
}
.TextLeftBorder1
{
  background: #08c7ff;
  position: absolute;
  top: 1px;
  left: -2px;
  height:100%;
  width: 2px;
  transform: rotate(0deg) skewY(-45deg);
}
.TextLeftBorder2
{
  background: #08c7ff;
  position: absolute;
  top: 1px;
  left: -2px;
  height:100%;
  width: 2px;
  transform: rotate(0deg) skewY(-45deg);
}

/*-----------------------*/
/*------TEXT RIGHT BORDER---*/
.TextRightBorder0
{
  background: #9feaff;
  position: absolute;
  bottom: -2px;
  left: -1px;
  height: 2px;
  width: 100%;
  transform: rotate(0deg) skewX(-45deg);
}
.TextRightBorder1
{
  background: #9feaff;
  position: absolute;
  bottom: -2px;
  left: -1px;
  height: 2px;
  width: 100%;
  transform: rotate(0deg) skewX(-45deg);
}
.TextRightBorder2
{
  background: #9feaff;
  position: absolute;
  bottom: -2px;
  left: -1px;
  height: 2px;
  width: 100%;
  transform: rotate(0deg) skewX(-45deg);
}

/*----------------*/

/*ITEM FOR 4 ICONS*/
.item0_0 {
  position: absolute;
  top: 48%;
  left: 26%;
  cursor: pointer;
  z-index: 2;
}
.item1_1 {
  position: absolute;
  top: 32.5%;
  left: 40%;
  cursor: pointer;
  z-index: 2;
}
.item2_2 {
  position: absolute;
  top: 16%;
  left: 53.5%;
  cursor: pointer;
  z-index: 2;
}

.item3_3 {
  position: absolute;
  top: 0%;
  left: 68%;
  cursor: pointer;
  z-index: 2;
}
/*---------*/
/*----TEXT ----*/

.iconName0_0
{
    min-width: 75px;
    padding: 5px 7px;
    background: #fff;
    position: absolute;
    top: 101%;
    left: 25%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: Averta-Regular;
    color: #0081c4;
    font-size: 8px;
    -webkit-transform: perspective(1000px) rotate(-30deg) skew(30deg) translate(0);
    transform: perspective(1000px) rotate(-30deg) skew(30deg) translate(0);
}
.iconName1_1
{
    min-width: 75px;
    padding: 5px 7px;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 25%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: Averta-Regular;
    color: #0081c4;
    font-size: 8px;
    -webkit-transform: perspective(1000px) rotate(-30deg) skew(30deg) translate(0);
    transform: perspective(1000px) rotate(-30deg) skew(30deg) translate(0);
}
.iconName2_2
{
    min-width: 75px;
    padding: 5px 7px;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 25%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: Averta-Regular;
    color: #0081c4;
    font-size: 8px;
    -webkit-transform: perspective(1000px) rotate(-30deg) skew(30deg) translate(0);
    transform: perspective(1000px) rotate(-30deg) skew(30deg) translate(0);
}
.iconName3_3
{
    min-width: 75px;
    padding: 5px 7px;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 25%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: Averta-Regular;
    color: #0081c4;
    font-size: 8px;
    -webkit-transform: perspective(1000px) rotate(-30deg) skew(30deg) translate(0);
    transform: perspective(1000px) rotate(-30deg) skew(30deg) translate(0);
}
/*-------------*/
/*----TEXT LEFT BORDER---*/
.TextLeftBorder0_0
{
  background: #08c7ff;
  position: absolute;
  top: 1px;
  left: -2px;
  height:100%;
  width: 2px;
  transform: rotate(0deg) skewY(-45deg);
}
.TextLeftBorder1_1
{
  background: #08c7ff;
  position: absolute;
  top: 1px;
  left: -2px;
  height:100%;
  width: 2px;
  transform: rotate(0deg) skewY(-45deg);
}
.TextLeftBorder2_2
{
  background: #08c7ff;
  position: absolute;
  top: 1px;
  left: -2px;
  height:100%;
  width: 2px;
  transform: rotate(0deg) skewY(-45deg);
}
.TextLeftBorder3_3
{
  background: #08c7ff;
  position: absolute;
  top: 1px;
  left: -2px;
  height:100%;
  width: 2px;
  transform: rotate(0deg) skewY(-45deg);
}
/*-----------------------*/
/*------TEXT RIGHT BORDER---*/
.TextRightBorder0_0
{
  background: #9feaff;
  position: absolute;
  bottom: -2px;
  left: -1px;
  height: 2px;
  width: 100%;
  transform: rotate(0deg) skewX(-45deg);
}
.TextRightBorder1_1
{
  background: #9feaff;
  position: absolute;
  bottom: -2px;
  left: -1px;
  height: 2px;
  width: 100%;
  transform: rotate(0deg) skewX(-45deg);
}
.TextRightBorder2_2
{
  background: #9feaff;
  position: absolute;
  bottom: -2px;
  left: -1px;
  height: 2px;
  width: 100%;
  transform: rotate(0deg) skewX(-45deg);
}
.TextRightBorder3_3
{
  background: #9feaff;
  position: absolute;
  bottom: -2px;
  left: -1px;
  height: 2px;
  width: 100%;
  transform: rotate(0deg) skewX(-45deg);
}
/*----------------*/
.icon0 {
  position: absolute;
  top: 24%;
  left: 56.5%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 85px;
  height: 60px;
  cursor: pointer;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
  display: none;
}
.icon1 {
  position: absolute;
  top: 48.5%;
  left: 35.5%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 80px;
  height: 60px;
  cursor: pointer;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}
.icon2 {
  position: absolute;
  top: 79%;
  left: 12%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 80px;
  height: 60px;
  cursor: pointer;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}
/*ICON FOR 4 ICON*/
.icon0_0 {
  position: absolute;
  top: 21%;
  left: 59%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 80px;
  height: 60px;
  cursor: pointer;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
  display: none;
}
.icon1_1 {
  position: absolute;
  top: 53%;
  left: 31%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 80px;
  height: 60px;
  cursor: pointer;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}
.icon2_2 {
  position: absolute;
  top: 78%;
  left: 14%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 80px;
  height: 60px;
  cursor: pointer;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}
.icon3_3 {
  position: absolute;
  top: 82%;
  left: 10%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 80px;
  height: 60px;
  cursor: pointer;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}
/*---------------*/
.arrow0 {
  position: absolute;
  top: 28px;
  right: 352px;
  width: 82px;
  height: 2px;
  background-color: #00b4ff;
}
.arrow1 {
  position: absolute;
  top: 31px;
  left: -9px;
  width: 78px;
  height: 2px;
  background-color: #00b4ff;
}
.arrow2 {
  position: absolute;
  top: 16px;
  left: 360px;
  width: 114px;
  height: 2px;
  background-color: #00b4ff;
}

/* ARROW FOR 4 ICON */
.arrow0_0 {
  position: absolute;
  top: 28px;
  right: 370px;
  width: 70px;
  height: 2px;
  background-color: #00b4ff;
}
.arrow1_1 {
  position: absolute;
  top: 31px;
  left: 30px;
  width: 37px;
  height: 2px;
  background-color: #00b4ff;
}
.arrow2_2 {
  position: absolute;
  top: 16px;
  left: 301px;
  width: 34px;
  height: 2px;
  background-color: #00b4ff;
}
.arrow3_3 {
  position: absolute;
  top: 16px;
  left: 449px;
  width: 43px;
  height: 2px;
  background-color: #00b4ff;
}
/*-----------------*/
.arrow0:after, .arrow0_0:after, .arrow1:after, .arrow1_1:after, .arrow2:after, .arrow2_2:after, .arrow3_3:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -4px;
  right: -2px;
  border-top: 5px solid transparent;
  border-left: 10px solid #00b4ff;
  -webkit-transform: perspective(1000px) rotate(0deg) skew(30deg) translate(0);
  transform: perspective(1000px) rotate(0deg) skew(30deg) translate(0);
  border-bottom: 5px solid transparent;
}
.arrow0::after {
  content: '';
  position: absolute;
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: -4px;
  right: -2px;
  border-top: 5px solid transparent;
  border-left: 10px solid #00b4ff;
  border-bottom: 5px solid transparent;
}
.arrow1::after {
  content: '';
  position: absolute;
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: -4px;
  right: -2px;
  border-top: 5px solid transparent;
  border-left: 10px solid #00b4ff;
  border-bottom: 5px solid transparent;
}
.arrow2::after {
  content: '';
  position: absolute;
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: -4px;
  right: -2px;
  border-top: 5px solid transparent;
  border-left: 10px solid #00b4ff;
  border-bottom: 5px solid transparent;
}
/*ARROW AFTER 4 ICONS*/
.arrow0_0::after {
  content: '';
  position: absolute;
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: -4px;
  right: -2px;
  border-top: 5px solid transparent;
  border-left: 10px solid #00b4ff;
  border-bottom: 5px solid transparent;
}
.arrow1_1::after {
  content: '';
  position: absolute;
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: -4px;
  right: -2px;
  border-top: 5px solid transparent;
  border-left: 10px solid #00b4ff;
  border-bottom: 5px solid transparent;
}
.arrow2_2::after {
  content: '';
  position: absolute;
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: -4px;
  right: -2px;
  border-top: 5px solid transparent;
  border-left: 10px solid #00b4ff;
  border-bottom: 5px solid transparent;
}
.arrow3_3::after {
  content: '';
  position: absolute;
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: -4px;
  right: -2px;
  border-top: 5px solid transparent;
  border-left: 10px solid #00b4ff;
  border-bottom: 5px solid transparent;
}
/*ACTIVE INTERACTION*/
.divServices
{
  border: 2px solid transparent;
  padding:2px;
  border-radius:5px;
  width:70px;
}
/*.activeState ,.divServices:hover {
  border: 2px solid #008cff;
  background-color: #a9d7ff;
}*/
.hoverState
{
    position: absolute;
    left: -7px;
    height: 99px;
    width: 82px;
    border-radius: 10px;
}
.activeState ,.hoverState:hover
{
    position: absolute;
    left: -7px;
    height: 99px;
    width: 82px;
    border: 2px solid #008cff;
    background: #008cff38;
    -webkit-transform: rotate(-30deg) skewX(-35deg);
    transform: rotate(-30deg) skewX(-31deg);
    border-radius: 10px;
    z-index: -99999;
}
/*.activeState ,.divServices:hover {
  position: absolute;
    left: -20px;
    height: 114px;
    border: 2px solid #008cff;
    background: #008cff38;
    width: 110px;
    -webkit-transform: rotate(-30deg) skewX(-35deg);
    transform: rotate(-30deg) skewX(-31deg);
    border-radius: 10px;
    z-index: -99999;
}*/
.Boardtitle
	{
	padding-bottom: 8px;
    padding-top: 8px;
    border: 2px solid transparent;
    font-size: 14.4PX;
    padding-left: 8px;
	cursor: pointer;
	}
	.activeList, .Boardtitle:hover
	{
		padding-bottom: 8px;
		padding-top: 8px;
		border: 2px solid #008cff;
		font-size: 14.4PX;
		padding-left: 8px;
		border-radius: 8px;
		cursor: pointer;
  }
  .Roletitle
	{
	  padding-bottom: 8px;
    padding-top: 8px;
    padding-left:10px;
    border: 2px solid transparent;
    font-size: 12PX;
    cursor: pointer;
    color: #105f89;
	}
	.activeRole, .Roletitle:hover
	{
		padding-bottom: 8px;
		padding-top: 8px;
		border: 2px solid #008cff;
		font-size: 12PX;
		border-radius: 8px;
		cursor: pointer;
  }
  .screentitle
	{
	  padding-bottom: 8px;
    padding-top: 8px;
    border: 2px solid transparent;
    font-size: 14PX;
    cursor: pointer;
    color: #105f89;
	}
  .selectIcon
  {
    padding-top:10px;
    padding-bottom:10px;
    border: 3px solid transparent;
    /*height:170px;*/
  }
  .activeIcon,.selectIcon:hover
  {
    border: 3px solid #008cff;
    background-color: #e3efff;
    padding-top:10px;
    padding-bottom:10px;
    border-radius:5px;
    /*height:170px;*/
    /*background-image: url(../src/images/IconTick.png);
    background-repeat: no-repeat;
    background-position: right top;*/
  }
  .activeIconContent
  {
    border: 3px solid #008cff;
    background-color: #e3efff;
    padding-top:10px;
    padding-bottom:10px;
    border-radius:5px;
  }
  .progressFull
  {
    /*border: 3px solid #008cff;
    background-color: #e3efff;
    padding-top:10px;
    border-radius:5px;
    background-image: url(../src/images/IconTick.png);*/
    background-image: url(../src/images/newtick.svg);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: right top;
  }
  p {
    margin-bottom:0px!important;
  }
  .messengerPage
  {
    padding-bottom: 6px;
    padding-top: 6px;
    font-size: 14.4PX;
    padding-left: 8px;
    width: 160px;
    word-break: break-word;
  }
  .labelText
  {
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(147,177,209,1);
}
.versionResult
  {
    font-family: Averta-Light;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(36,82,126,1);
}
.labelTextEmoji
  {
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(147,177,209,1);
    margin-top: 30px;
}
.labelTextResult
  {
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(147,177,209,1);
    clear: both;
    margin-top: 78px;
    text-align: left;
}
.labelSubText
  {
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color:#24527e;
    /*margin-left: -12px;*/
}
.fileType
{
  width: 189px;
  background-color: #fff;
  display:block;
}
.txtType
{
  width: 189px;
  line-height: 18px;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  -moz-appearance: none;
}
.inputForuser
{
  border: 1px solid #a9d7ff!important;
}
.inputForuserLive
{
  border: 1px solid #E3EFFF!important;
  padding:10px 17px;
}
.widthForpanel3text
{
  width:271px;
}
.txtTypeChat
{
  width: 290px;
  line-height: 18px;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-top: 310px;
  height: 92px;
}
.txtFileType
{
  width: 189px;
  line-height: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}
.row{
  margin-right: 0px;
}
.workflow_illustration_v5
{
  border: 2px solid #008cff!important;
  border-radius: 8px;
}
button[disabled] {
  /*background-color:#CCC;*/
  background-color: #a9d7ff;
  color: #fff;
  border: 1px solid #a9d7ff;
}
select[disabled] {
  background-color: #e3efff;
}
button[disabled]:hover {
  /*background-color:#CCC;*/
  background-color: #a9d7ff;
  color: #fff;
  border: 1px solid #a9d7ff;
}
.disabled{
  /*background-color: #CCC!important;*/
  background-color: #a9d7ff!important;
  color: #fff!important;
  border: 1px solid #a9d7ff!important;
  text-decoration: none!important;
}
.disabled:hover{
  text-decoration: none!important;
}
/*FACEBOOK BUTTON*/
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /*margin: 0.2em;*/
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  font-size: 16px;
  color: #FFF;
  cursor: pointer;
  color: transparent;
  background-image: url('images/buttons/fblogin.svg')!important;
  background-repeat: no-repeat;
  /*top:20px;border-radius: 20px;*/
}
/*.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
  
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}*/


/* Facebook */
/*.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;
  text-shadow: 0 -1px 0 #354C8C;
}*/
/*.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}*/
.btn_content {
  background-color: #008CFF !important;
  width: 78px;
  color: #fff !important;
  border-radius: 37px;
  height: 30px;
  border-top: 2px solid #4caeff !important;
  border-bottom: 1px solid #4caeff !important;
  margin:0px!important;
  padding:0px!important;
  box-shadow: 0 8px 6px -6px #008CFF;
}
/*TOGGLE BUTTON*/
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switchSquare
{
  border: 1px solid #fff;
  height: 70px;
  width: 70px;
  background-color: #ffffff;
  padding: 23px 11px;
  border-radius: 8px;
  margin-top: 20px;
}
.switchSquare_2
{
    border: 1px solid #fff;
    height: 144px;
    width: 144px;
    background-color: #ffffff;
    padding: 23px 11px;
    border-radius: 8px;
    margin-top: 20px;
    text-align: center;
}
.switchSquare_3
{
    border: 1px solid #fff;
    height: 155px;
    width: 235px;
    background-color: #ffffff;
    padding: 23px 11px;
    border-radius: 8px;
    margin-top: 20px;
    text-align: center;
}
.switchSquare_4
{
  border: 1px solid #93b1d1;
  background-color: #ffffff;
  padding: 7px 8px;
  border-radius: 15px;
  margin-top: 20px;
  overflow: hidden;
  min-height: 157px!important;
}
.switchSquare_content2
{
  border: 1px solid #93b1d1;
  background-color: #ffffff;
  padding: 7px 8px;
  border-radius: 15px;
  margin-top: 20px;
  overflow: hidden;
}
.rectangleBox_1
{
    border: 1px solid #93b1d1;
    background-color: #ffffff;
    padding: 5px 5px;
    border-radius: 15px;
    margin-top: 10px;
    overflow: hidden;
    width: 157px;
    
}
.activeUser
{
  border: 2px solid #008cff;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}
.activeOrg
{
  border: 2px solid #008cff!important;
  background-color: #ffffff;
  padding: 7px 8px;
  border-radius: 15px;
  margin-top: 20px;
  overflow: hidden;
  min-height: 157px!important;
}
.switchSquare_5
{
  border: 1px solid #93b1d1;
  background-color: #ffffff;
  padding: 7px 8px;
  border-radius: 15px;
  margin-top: 20px;
  overflow: hidden;
  min-height: 100px!important;
}
.paddingAlign{
  padding-top: 35px;
}
/*SIDE MENU CSS*/
.checkbox-opacity + .submenuAfter::after {
  transform: rotate(90deg);
}
.checkbox-opacity {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.tabs {
  border-radius: 8px;
  overflow: hidden;
}
.tab {
  width: 100%;
  overflow: hidden;
  font-family: Averta-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(0,140,255,1);
  word-break: break-word;
  margin-top:5px
  /*padding: 10px 10px;*/
}
.tab-label {
  display: flex;
  /*justify-content: space-between;*/
  padding: 10px 0px 10px 16px;
  background: transparent;
  cursor: pointer;
  float: left;
  word-break: break-word;
  width: 117px;
  line-height: 1.2;
  margin-bottom:0px;
  /*background-image: url('./images/accordian.png');
  background-repeat: no-repeat;
  background-position: right;*/
}

/*.submenuAfter::after {
  content: "\276F";
  content: "\f107";
  width: 1em;
  height: 1em;
  margin-left: 48px;
}
.rotateIcon
{
  content: "\f107";
}*/
.faIconRotate
{
    /*font-weight: bold;*/
    margin-left: 65px;
    font-size: 20px;
    margin-top: 10px;
}

input:checked + .submenuAfter::after {
  transform: rotate(90deg);
}
.tab-content {
  max-height: 0;
  
  color: #008cff;
  background: #e3efff;
  /*transition: all 0.35s;*/
}


.clear {
  clear: both;
}
.menu-expand-bg {
  background: #e3efff;
  
  /*padding: 0px 0px 0px 11px;*/
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  /*height:235px;
  padding-bottom:10px;*/
  margin-left: -16px;
  width: 250px;
}

.menu-expand-extraspace {
  padding: 0px 0px 0px 15px;
}
/*.tab-first-change {
  border-top-left-radius: 25px;
}
.tab-last-change {
  border-bottom-left-radius: 25px;
  padding-bottom: 20px;
}*/
.activeSubmenu.tab-last-change {
  margin-bottom: 4px;
}



.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.tab-close:hover {
  background: #1a252f;
}
input:checked + .tab-label {
  background: #1a252f;
}
input:checked + .tab-label::after {
  transform: rotate(90deg);
}
input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 8px 0px 18px 36px;
  /*padding-top: 25px;
  padding-left:47px;*/
}
.margin-menu
{
  margin-top:30px;
}
.margin-align{
  margin-top:10px;
}
.margin-align_30{
  margin-top:30px;
}
.margin-align_20{
  margin-top:20px;
}
.submenu-align
{
  padding-bottom:20px;
}
.img_close
{
  margin-top:24px;
  float:left;
}
.img_close2
{
  margin-top:0px;
  float:right;
}
.small_button_edit {
  background-color: #fff;
  border: 0.5px solid #ccc;
  color: rgb(36, 82, 126);
  padding: 4px 8px;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  border-radius: 30px;
  outline: none;
  font-family: "Averta-Regular";
}
/*COGNITO LOGIN*/
.Section__container___3YYTG
{
  /*background-image: url("images/SmartFeedTrendingT_1Screen_elRectangleCopy.png") ;
  width: auto;
  background-size: cover;
  margin: 0 auto;*/

    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 500px;
    height: 500px;
}
.Form__formContainer___1GA3x {
  margin-top: 0px !important;
  margin: 0 !important;
}
.Form__formSection___1PPvW
{
  border-radius: 18px !important;
  /*left: 33% !important;
  position: absolute !important;
  border: 1px solid #4caeff;
  */
  padding:40px!important;
  box-shadow: 0px 0px 24px rgba(0, 112, 204, 0.20)!important;
  margin-top: 20px !important;
}
.Section__sectionHeader___2djyg
{
  height: 32px;
  /*background-image: url("images/blLogo2.svg");*/
  background-image: url("images/blLogo1.svg");
  background-repeat: no-repeat;
  background-size: 190px 32px;
  padding-left: 10px;
}
.Section__sectionBody___ihqqd
{
  margin-top:70px;
}
/*.Section__sectionHeaderContent___1UCqa {
  visibility: hidden;
  position: relative;
}*/
/*.Section__sectionHeaderContent___1UCqa
{
    visibility: visible;
    top: 45px;
    position: absolute;
    font-size: 20px !important;
    color: rgba(0, 0, 0, 0.8500) !important;
    content: "Log In to your Account";
    width: 252px;
    left: 0px;
}*/
.Section__sectionHeaderContent___1UCqa
{
    top: 90px;
    position: absolute;
    font-size: 24px !important;
    color: #24527E !important;
    font-family: Averta-Regular;
    left: 39px;
}
.Form__formField___38Ikl
{
  margin-bottom: 0px!important;
  margin-top:30px;
}
.Input__inputLabel___3VF0S
{
  color: #24527E !important;
  font-family: Averta-Bold;
  font-size: 12px;
}
.Input__input___3e_bf
{
  color:#24527e !important;
  padding:10px 25px !important;
  border: 1px solid #a9d7ff !important;
  border-radius: 30px !important;
  height: 44px !important;

}
.Input__input___3e_bf::placeholder
{
    opacity:0.3;
}
.Input__input___3e_bf:focus
{
  background-color: #FFF !important;
}
.Input__input___3e_bf:active
{
  background-color: #FFF !important;
}
.Button__button___vS7Mv
{
    background-color: #008CFF !important;
    width: 120px !important;
    color: #fff !important;
    border-radius: 30px !important;
    /*height: 40px !important;*/
    /*border-top: 4px solid #4caeff !important;*/
    border: 0.5px solid #008CFF !important;;
    padding: 9px 22px !important;
    min-width: 0px !important;
    height:36px;
}
.Button__button___vS7Mv:hover
{
	background-color: #0070CC!important;
}
.Button__button___vS7Mv:focus,.Button__button___vS7Mv:active {
	outline: none !important;
  box-shadow: none;
  background-color: yellow;
 }

 .Button__button___vS7Mv[disabled] {
	border: 1px solid #a9d7ff!important;
	color:#a9d7ff!important;
  background-color: #fff!important;
  background-image: url("images/provision.gif")!important;
  background-repeat: no-repeat!important;
  background-size: 20px 20px;
  background-position: center left 12px;
  padding-left:37px!important;
 }
 .Button__button___vS7Mv[disabled]:hover {
	border: 1px solid #a9d7ff!important;
	color:#a9d7ff!important;
  background-color: #fff!important;
  background-image: url("images/provision.gif")!important;
  background-repeat: no-repeat!important;
  background-size: 20px 20px;
  background-position: center left 12px;
  padding-left:37px!important;
 }
 
/*.Anchor__a___1_Iz8{
  color: #24527e !important;
  margin-left :10px;
  font-size: 12px;
  font-family: Averta-Regular;
}*/
.Hint__hint___2XngB{
  visibility: hidden;
}
/**/.Anchor__a___1_Iz8 {
  visibility: hidden;
  position: relative;
}
/**/.Anchor__a___1_Iz8:after
{
    visibility: visible;
    position: absolute;
    font-size: 12px !important;
    color: #24527E !important;
    content: "Forgot your Password?";
    width: 126px;
    left: -126px;
    border-bottom: 1px dashed;
}
.SelectInput__selectInput___3efO4 > select
{
  color:#24527e !important;
  padding:8px 12px !important;
  border: 1px solid #a9d7ff !important;
  border-radius: 30px !important;
}
input[type=tel]
{
  border-radius: 30px !important;
}
.Input__input___3e_bf
{
  border-radius: 30px !important;
}
.Section__sectionFooter___1T54C > .Section__sectionFooterSecondaryContent___Nj41Q
{
  display:none;
}
input:focus,input:active {
  border: 1px solid #a9d7ff !important;
	outline: none !important;
	box-shadow: none;
 }
.profile_img{
  width: 60px;
  height: 60px;
  margin-left: 0px;
  border-radius: 50%;
  margin-top: 5px;
}
.imgAv
{
  border: 2px solid transparent;
}
.imgAv:hover {
  border: 2px solid #008CFF;
  /*width:65px;
  height:65px;
  margin-top: 0px;
  padding:5px;*/
}
.imgAv:focus {
  border: 2px solid #008CFF;
  outline: none;
}
.selectedProfImg
{
  border: 2px solid #008CFF;
  width:65px;
  height:65px;
  margin-top: 0px;
  padding:5px;
}
.img_button_updt{
    position: absolute;
    top: 74.2%;
    z-index: 2;
    left: 61.7%;
    width:88.5px;
    height:61px;
}
.img_button_rmv
{
  position: absolute;
  top: 59.4%;
  z-index: 2;
  left: 83.5%;
  height:28.9px;
  width:36.5px
}
.img_button_new
{
  position: absolute;
  top: 63.5%;
  z-index: 2;
  left: 73.5%;
  height: 50.5px;
  width: 70.5px;
}
.img_button_new_disable
{
  position: absolute;
  top: 63%;
  z-index: 2;
  left: 73.5%;
  height: 50.5px;
  width: 70.5px;
}
/*FAQ LIST*/
.faq_fa_icon
{
    width: 25px;
    height: 25px;
    padding: 5px;
    border-radius: 50%;
    margin-right: 5px;
    background: #cce0f1;
    border: 1px solid #24527e;
    color:#24527e;
}
.dropdown_accordian_faq {
	/**/width: 220px;
	/*color: white;*/
  overflow: hidden;
  font-size:14px;
}
.dropdown_accordian {
	width: 310px;
	/*color: white;*/
  overflow: hidden;
  font-size:14px;
}
.dropdown_accordian-label {
	display: flex;
	justify-content: space-between;
	padding: .6em;
	background: #cce0f1;
	border: 1px solid #a9d7ff;
	cursor: pointer;
	border-radius: 30px;
  color: #24527e;/* Icon */
  /*width: 170px;*/
  /*padding-left: 3em;*/
  padding:10px 32px;
  font-size: 12px;
}

.dropdown_accordian-label::after {
	content: "\276F";
	width: 1em;
	height: 1em;
	text-align: center;
	transition: all 0.35s;
}
.dropdown_accordian-content {
	max-height: 0;
	padding: 0 1em;
	color: #2c3e50;
	background: white;
  transition: all 0.35s;
  /*width: 165px;*/
  font-size: 12px;
}
.dropdown_accordian-close {
	display: flex;
	justify-content: flex-end;
	padding: 1em;
	font-size: 0.75em;
	cursor: pointer;
}
.dropdown_accordian-close:hover {
	background: #1a252f;
}

.acc_chkbox:checked + .dropdown_accordian-label::after {
	transform: rotate(90deg);
}
.acc_chkbox:checked ~ .dropdown_accordian-content {
	max-height: 100vh;
	padding: 1em;
	/*margin-left: 15px;*/
	margin-right: 15px;
  background: #e4e4e4;
  word-break: break-word;
}
.roundShapedEdit
{
  background: #cce0f1;
  padding: 4px;
  border: 1px solid #24527e;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 26px;
  color:#24527e;
  position: absolute;
  margin-top: 2px;
  left: 6px;
}
.roundShapedDeleteWeb
{
  background: #cce0f1;
  padding: 4px;
  border: 1px solid #24527e;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 26px;
  color:#24527e;
  position: absolute;
  margin-top: 4px;
  left: 5px;
}
.roundShapedDelete
{
  background: #cce0f1;
  padding: 4px;
  border: 1px solid #24527e;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 26px;
  color:#24527e;
  position: absolute;
  margin-top: 2px;
  left: 32px;
}
.editIconFa
{
  position: absolute;
  top: 5px;
  left: 6px;
}

.backgoround-zindex
{
  border: 1px solid transparent;
  position: absolute;
  z-index: 1;
  height: 371px;
  width: 738px;
}
.no-margin
{
  margin:0px!important;
}
.padding_top_bottom
{
  padding:30px 0px
}
.padding_small_Top
{
  padding-top: 30px;
  padding-bottom: 30px;
}
.sentiment-align_1{
  margin-top: 40px;
  margin-left: 94px;
  height:610px;
}
.sentiment-align_2{
  margin-top: 39px;
  margin-left: 20px;
  height:610px;
}
.resizeImg
{
  width: 367px;
  height: 613px;
}
.white-background
{
  background-color: #fff;
  border-radius: 18px;
}
.chat-message-bot
{
	float:left;
	padding:8px 16px;
	background-color:#e3efff;
	width: 70%;
	color: #006b93;
	position: relative;
	border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
	font-size: 12px;
}
.chat-message-user
{
	float:right;
	padding:8px 16px;
	background-color:#008cff;
	width: 70%;
	color: #ffffff;
	position: relative;
	border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
	font-size: 12px;
}
.availableIconSize
{
  height:70px;
  width:40px;
}
.backgroundSuccess
{
  color: #5cb85c;
}
.backgroundWarning{
  color: #f0ad4e;

}
.backgroundError{
  color: #d9534f;
}
.settingsImgAdjust
{
  margin-top:6px;
  margin-left:7px;
}
.nameAdjustment
{
  padding-top:3px;
  font-size:14px;
}
.changeBackgroundColor
{
  background-color: #f2f7ff;
}
#chatImg
{
  height:50px;
  width:50px;
  border-radius: 50%;
  margin-left: 20px;
}
.divider
{
  border-bottom: 1px solid rgba(147, 177, 209, 0.3);
  margin:5px 0px;
  width: 198px;
  margin-left: 10px;
}
.margin-menu-20
{
  margin-top: 20px;
}
.noPadding
{
  padding-left:0px!important;
}
.removeReadonly
{
  background: transparent!important;
  border: 1px solid transparent!important;
}
.colorChange
{
  color: #105f89;
  font-size: 14px;
}
.loaderClass{
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
  z-index: 1;
  /*height:100px;*/
  width:100px;
}
.overlayLoader{
  position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    /*background: rgba(169,215,255,0.3);
    background: rgba(255, 255, 255, 0.75);*/
    background: hsla(0,0%,100%,.75);
    z-index: 999;
    transition: opacity 0.5s ease-in-out;
}

.overlayLoaderDenied{
  position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(169,215,255,0.3);
    z-index: 1;

    -moz-animation: cssAnimation 0s ease-in 3s forwards;
  
  -webkit-animation: cssAnimation 0s ease-in 3s forwards;
  
  -o-animation: cssAnimation 0s ease-in 3s forwards;
  
  animation: cssAnimation 0s ease-in 3s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes cssAnimation {
  to {
      width:0;
      height:0;
      overflow:hidden;
  }
}
@-webkit-keyframes cssAnimation {
  to {
      width:0;
      height:0;
      visibility:hidden;
  }
}
.paddingFaq
{
  padding-left: 4.8em;
}
.ImpNoPadding
{
  padding:0px!important;
}
.Padding5
{
  padding:5px!important;
}
.plTnR10
{
  padding-top: 11px!important;
  padding-right: 3px!important;
}
.ImpNoPaddingwithMargin
{
  padding:0px!important;
  margin-top:20px!important
}
.ImpNoPaddingwithMarginRestOthers
{
  padding:0px!important;
  margin-top:7px!important
}
.ImpNoPaddingwithMarginRestCreated
{
  padding:0px!important;
  margin-top:5px!important;
  line-height: 1.2;
}
.ImpNoPaddingwithMarginChat1stlabel
{
  padding:0px!important;
  margin-top:0px!important;
  line-height: 1.2;
}
.ImpNosidePaddingColmd6
{
  padding-left:0px!important;
  padding-right:0px!important
}
.ml0{
  margin-left:0px!important
}
.ml5{
  margin-left:5px!important
}
.ml6{
  margin-left:6px!important
}
.ml7{
  margin-left:7px!important
}
.ml13{
  margin-left:13px!important
}
.ml15{
  margin-left:15px!important
}
.ml16{
  margin-left:16px!important
}
.ml20{
  margin-left:20px!important
}
.ml27{
  margin-left:27px!important
}
.ml32{
  margin-left:32px!important
}
.ml42{
  margin-left:42px!important
}
.mr0{
  margin-right:0px!important
}
.mr22{
  margin-right:22px!important
}
.mt0{
  margin-top:0px!important
}
.mb0{
  margin-bottom:0px!important
}
.pt60
{
  padding-top: 60px!important;
}
.pl0{
  padding-left: 0px!important;
}
.pl10{
  padding-left: 10px!important;
}
.pr0{
  padding-right: 0px!important;
}
.pb13
{
  padding-bottom: 13px;
}
.margin725
{
  margin-top:725px;
}
.iconLineHeight
{
  padding-top:10px;
  line-height: 1.2;
}
.orgname
{
  color: #24527e;
  font-size: 14px;
}
.noInteractionText
{
  font-size: 14px;
  /*text-align: justify;*/
  padding:15px;
  padding-top:5px;
  line-height: 1.2;
}
.noInteractionText_ribbon
{
  background-image: url('images/ribbon.svg');
  background-repeat: no-repeat;
  /*background-position: 7% 40%;
  background-size: auto 23px;*/
  font-size: 9px;
  color: #fff;
  font-weight: bold;
  height: 22px;
  line-height: 17px;
  width: 129px;
}
.noInteractionText_ribbon_live
{
  background-image: url('images/ribbon.svg');
  background-repeat: no-repeat;
  /*background-position: 7% 40%;
  background-size: auto 23px;*/
  font-size: 9px;
  color: #fff;
  font-weight: bold;
  height: 22px;
  line-height: 14px;
  width: 129px;
}
.comingsoon_text
{
  margin-left:5px;
  letter-spacing: 2px;
}
.noInteractionHeader
{
  padding:15px;
  padding-bottom:0px;
  line-height: 1.2;
}
.noInteractionHeader_ribbon
{
  /*padding:15px;
  padding-bottom:0px;
  padding-left: 0px;*/
  line-height: 1.2;
  font-size: 16px;
}
.userListScroll
{
  margin-top: 10px;
  height: 278px;
  overflow-y: auto;
}
.comingSoonBox
{
    border: 1px solid #d1eaff;
    padding: 14px 17px;
    top: 32px!important;
    border-radius: 11px;
    width: 268px!important;
    background: #d1eaff;
    color: #105f89!important;
    font-size: 14px!important;
    height:80px;
}
.provisionWaitbox
{
    border: 1px solid #d1eaff;
    padding: 5px 5px 65px 5px;
    margin-top: 32px!important;
    border-radius: 11px;
    width: 268px!important;
    background: #d1eaff;
    color: #105f89!important;
    font-size: 14px!important;
    height:65px;
}
.heightForInactive
{
  height:232px!important;
}
.filterStatus
{
  line-height: 0px!important;
  margin-left:20px;
}
.scrollForInteraction{
  height:808px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 12px;
  padding-top: 4px;
}
.dividerFullWidth
{
    border-bottom: 1px solid rgba(147, 177, 209, 0.3);
    margin: 20px 0px;
    width: 100%;
}
.dividerFullWidthOthers
{
    border-bottom: 1px solid rgba(147, 177, 209, 0.3);
    margin: 20px 0px;
    width: 302px;
    margin-left:-17px;
}
.margin-align .spanText
{
  margin-top:10px;
  display:block;
}
.mt4
{
  margin-top: 4px!important;
}
.mt5
{
  margin-top: 5px!important;
}
.mt6
{
  margin-top: 6px!important;
}
.mt7
{
  margin-top: 7px!important;
}
.mt8
{
  margin-top: 8px!important;
}
.mt9
{
  margin-top: 9px!important;
}
.mt10
{
  margin-top: 10px!important;
}
.mt11
{
  margin-top: 11px!important;
}
.mt12
{
  margin-top: 12px!important;
}
.mt14
{
  margin-top: 14px!important;
}
.mt15
{
  margin-top: 15px!important;
}
.mt16
{
  margin-top: 16px!important;
}
.mt18
{
  margin-top: 18px!important;
}
.mt19
{
  margin-top: 19px!important;
}
.mt20
{
  margin-top: 20px!important;
}
.mt22
{
  margin-top: 22px!important;
}
.mt23
{
  margin-top: 23px!important;
}
.mT24
{
  margin-top: 24px;
}
.mt25
{
  margin-top: 25px!important;
}
.mt26
{
  margin-top: 26px!important;
}
.mt27
{
  margin-top: 27px!important;
}
.mt29
{
  margin-top: 29px!important;
}
.mt31
{
  margin-top: 31px!important;
}
.mt32
{
  margin-top: 32px!important;
}
.mt33
{
  margin-top: 33px!important;
}
.mt40
{
  margin-top: 40px!important;
}
.mt41
{
  margin-top: 41px!important;
}
.mt100
{
  margin-top: 100px!important;
}
.mt190
{
  margin-top: 190px!important;
}
.mt45
{
  margin-top: 45px!important;
}
.mt55
{
  margin-top: 55px!important;
}

.mr7{
  margin-right: 7px!important;
}
.mr20
{
  margin-right: 20px!important;
}
.mr26
{
  margin-right: 26px!important;
}
.ml0
{
  margin-left: 0px!important;
}
.ml3
{
  margin-left: 3px!important;
}
.ml8
{
  margin-left: 8px!important;
}
.ml9
{
  margin-left: 9px!important;
}
.ml10
{
  margin-left: 10px!important;
}
.ml11
{
  margin-left: 11px!important;
}
.ml12
{
  margin-left: 12px!important;
}
.ml13{
  margin-left: 13px!important;
}
.ml16{
  margin-left: 16px!important;
}
.ml20{
  margin-left: 20px!important;
}
.ml24
{
  margin-left: 24px!important;
}
.ml25
{
  margin-left: 25px!important;
}
.ml30
{
  margin-left: 30px!important;
}
.ml35
{
  margin-left: 35px!important;
}
.ml38
{
  margin-left: 38px!important;
}
.mb0{
  margin-bottom: 0px!important;
}
.mb10
{
  margin-bottom: 10px!important;
}
.mb15
{
  margin-bottom: 15px;
}
.mb12
{
  margin-bottom: 12px!important;
}
.pl0{
  padding-left: 0px!important;
}
.pt0{
  padding-top: 0px!important;
}
.w-74
{
  width:74px!important;
}
.w-101
{
  width:101px!important;
}
.w-120
{
  width:120px!important;
}
.w-132
{
  width:132px!important;
}
.w-138
{
  width:138px!important;
}
.w-139
{
  width:139px!important;
}
.w-170
{
  width:170px!important;
}
.w-200
{
  width:200px!important;
}
.w-267
{
  width:267px!important;
}
.w-228
{
  width:228px!important;
}
.w-187
{
  width:187px!important;
}
.w-205
{
  width:205px!important;
}
.w-254
{
  width:254px!important;
}
.w-270
{
  width:270px!important;
}
.w-165
{
  width:165px!important;
}
.w-300
{
  width:300px!important;
}
.w-330
{
  width:330px!important;
}
.w-355
{
  width:355px!important;
}
.w-500
{
  width:500px!important;
}
.w-1245
{
  width:1245px!important;
}
.h-36
{
  height:36px!important
}
.fs10{
  font-size: 10px!important;
}
.fs12{
  font-size: 12px!important;
}
.fs14{
  font-size: 14px!important;
}
.fs18{
  font-size: 18px!important;
}
.fs22{
  font-size: 22px!important;
}
.fs24{
  font-size: 24px!important;
}
.fs14{
  font-size: 14px!important;
  width: 100px;
}
.pr3{
  padding-right: 3px!important;
}
.clBlue
{
  color: #008CFF;
}
.displayInline
{
  display: inline!important;
}
.button_delete
{
  color: #F44336;
  background-color: transparent;
  text-decoration: underline;
  font-weight: bold;
  font-size: 10px;
}
.button_delete:focus,.button_delete:active
{
  outline: none !important;
  box-shadow: none;
}
.button_enable
{
  color: green;
  background-color: transparent;
  text-decoration: underline;
  font-weight: bold;
  font-size: 10px;
}
.button_enable:focus,.button_enable:active
{
  outline: none !important;
  box-shadow: none;
}
.button_delete[disabled],.button_delete[disabled]:hover
{
  color: #F9AFAA;
  background-color: transparent;
  border: none;
}
.lh0
{
  line-height: 0px!important;
}
.lh10
{
  line-height: 10px!important;
}
.lh12
{
  line-height: 12px!important;
}
.lh16
{
  line-height: 16px!important;
}
.LiveMonitoringImg
{
  width:1046px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper-broadcasting {
  position: relative;
  display: inline-block;
}
.upload_button {
  border: 1px dashed #008CFF !important;
  color: #008CFF!important;
  background-color: white!important;
  padding: 9px 22px !important;
  border-radius: 30px !important;
  font-size: 12px !important;
  cursor: pointer;
}
.upload_button[disabled] {
  border: 1px dashed #008CFF !important;
  background-color: #a9d7ff!important;
  color: #fff!important;
}
.upload-btn-wrapper-broadcasting input[type=file] {
  font-size: 10px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.upload-btn-wrapper input[type=file] {
  font-size: 10px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.headerforUserTable
{
  color: #105f89;
  font-size: 14px;
  font-weight: 600;
}
.textforUserTable
{
  color: #105f89;
  font-size: 12px;
}
.paddingAlignforUser
{
  padding:10px 0px 10px 0px;
}
.ReactModalPortal
{
  z-index: 999;
}

.moonbase{
  width: 116px;
  margin-left: -28px;
  margin-top: -35px;
  margin-bottom: -51px;
  }
.iconNameForCC
{
  top:119%!important;
}
/*MODAL CSS*/

/* The Modal (background) */
.modalBoard {
  display: none; /* Hidden by default */
  position: absolute; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 739px; /* Full width */
  height: 372px; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 140, 255, 0.45); /* Fallback color */
  border-radius: 8px;
  z-index: 99;
  align-items: center;
  justify-content: center;
}

/* Modal Content */
.modal_content_board {
  background-color: #f2f7ff;
  padding: 20px;
  padding-top:0px;
  width: 358px;
  height:217px;
  border-radius: 18px;
  /*margin-top: 77px;
  margin-left: 200px;*/
  box-shadow: 0px 12px 24px rgba(0, 112, 204, 0.566434);
  position: relative;
}
.pannel3ButtonTray {
  /*background-color: #f2f7ff;
  box-shadow: 0px 12px 24px rgba(0, 112, 204, 0.566434);*/
  width: 290px;
  border-radius: 12px;
  background-color: #E0EFFF;
  box-shadow: 0px 0px 30px rgba(0, 112, 204, 0.566434);
  bottom: 10px;
  position: absolute;
  height: 60px;
}
.close_img
{
  position: absolute;
  top: 15px;
  left: 322px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.bordernWidth
{
  border: 1px solid rgba(0, 140, 255, 0.3)!important;
  width: 273px!important;
}
.bordernWidthforPop
{
  border: 1px solid rgba(0, 140, 255, 0.3)!important;
  width: 232px!important;
}
.msgboardTextPosition
{
  position: absolute;
  top: 19%;
  left: 25%;
}
.msgBoard
{
  height:210px;
}
#modalHeaderText
{
	text-align: left;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
	color: #93b1d1;
}
#modalSubHeaderTxtContent
{
	text-align: center;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
	  color: rgba(36,82,126,1);
}
.ulForlm2
{
  list-style-type: none;
  overflow:hidden;
}
.ulForlm2 li{
  float: left;
}
.backlm2
{
  position: absolute;
  left: 300px;
  top: 44px;
}
.__react_component_tooltip.type-dark
{
  background-color: #24527E!important;
  font-family: Averta-italic!important;
  border-radius: 6px;
  padding-bottom: 10px;
}
.__react_component_tooltip.type-dark.place-top:after
{
  border-top-color: #24527E!important;
  
}
.__react_component_tooltip.type-dark.place-bottom:after
{
  border-bottom-color: #24527E!important;
}
.pannel3Modal {
  display: none; /* Hidden by default */
  position: absolute; /* Stay in place */
  top:0;
  right: 0;
  width: 312px; /* Full width */
  height: 896px; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 140, 255, 0.45); /* Fallback color */
  border-radius: 8px;
  z-index: 99;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.activeAdminDetails
{
    position: relative;
    z-index: 100;
    background-color: #fff;
    padding-left: 10px;
    margin-left: -17px;
    padding: 1px 0 14px 17px;
    width: 308px;
    border-radius: 10px;
    top: -18px;
}
.activeIntegrationDetails
{
    position: relative;
    z-index: 100;
    background-color: #fff;
    padding-left: 10px;
    margin-left: -17px;
    padding: 1px 0 14px 17px;
    width: 308px;
    border-radius: 10px;
    top:-15px;
}
.activeWaitMsg
{
    position: absolute;
    z-index: 100;
    background-color: #fff;
    padding-left: 10px;
    margin-left: -15px;
    padding: 1px 0 14px 17px;
    width: 299px;
    border-radius: 10px;
    bottom: 72px;
}
.userButtonAlign
{
  padding: 0!important;
  position: absolute!important;
  bottom: -2px;

}
.firstWhatsapp
{
  background-color: #fff;
  margin-left: 40px;
  margin-top: 36px;
  border-radius: 12px;
  padding: 36px;
  -webkit-box-shadow: 0px 0px 11px -2px rgba(0,140,255,0.35);
  -moz-box-shadow: 0px 0px 11px -2px rgba(0,140,255,0.35);;
  box-shadow: 0px 0px 11px -2px rgba(0,140,255,0.35);;
}
.livePtag
{
    font-size: 60px;
    padding: 0px;
    margin: 0px;
    line-height: 60px;
}
.colorAttention
{
  color:#FF3153!important;
}
.colorGreen {
  color:#29CC75!important;
}
.colorResolved
{
  color:#87B0E6!important;
}
.colorHandled
{
  color:#008CFF!important;
}
.colorNoAction
{
  color:#CEE2FD!important;
}

/**/
.table-fixed {
  width: 100%;
  background-color: #f3f3f3;
}
.table-fixed tbody {
  height: 278px;
  overflow-y: auto;
  width: 100%;
}
.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
  display: block;
}
.table-fixed tbody td {
  float: left;
}
.table-fixed thead tr th {
  float: left;
}
.provisioningForUserImg
{
  width: 230px!important;
  margin-left: 24px!important;
}
.ulforLiveMonitoring{
  list-style-type: none;
}
.ulforLiveMonitoring li{
  float: left;
  width: 90px;
  text-align: center;
}
.ulforLiveMonitoring li img{
  /*-webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);*/
    -webkit-filter: drop-shadow(0px 0px 8px rgba(0, 140, 255, 0.2));
    filter: drop-shadow(0px 0px 8px rgba(0, 140, 255, 0.2));
}
.arrowforLive {
  width:100px;
  /*margin:50px auto;*/
}
.circleforLive{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #008CFF;
  float: left;

}
.lineforLive {
  margin-top: 7px;
  width: 40px;
  background: #C3E4FF;
  height: 3px;
  float: left;
  z-index: 1;
  position: relative;
}
.lineforLiveTransparent {
  margin-top: 7px;
  width: 40px;
  background: transparent;
  height: 3px;
  float: left;
}
.pointforLive {	
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid #008CFF;
  float: right;
  margin-top: 3px;
  margin-left: -6px;
  z-index: 2;
  position: relative;
}
.pointforLiveTransparent {	
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid transparent;
  float: right;
  margin-top: 3px;
  margin-left: -6px;
}
.textForLive
{
  font-size:12px;
}
.analysisForLive
{
  float:left;
  width:102px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.livePtagForLive
{
    font-size: 28px;
    padding: 0px;
    margin: 0px;
    line-height: 28px;
    /*height: 50px;*/
    font-family: 'Averta-Light';
}
/*LIVE MONITORING ACCORDIAN*/
/*.tabsForLive {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}*/
.tabforLive {
  width: 100%;
  color: white;
  overflow: hidden;
}
.tab-label-live {
  display: flex;
  justify-content: space-between;
  padding: 19px 30px 19px 46px;
  color: #24527E;
  font-weight: bold;
  cursor: pointer;
  background: #FFFFFF;
  /* box-shadow: 0px 12px 34px rgba(0, 140, 255, 0.1); */
  border-radius: 12px;
 
}
/*.tab-label-live:hover {
  background: #1a252f;
}*/
.tab-label-live::after {
  content: "\276F";
  /*width: 1em;
  height: 1em;*/
  text-align: center;
  transition: all 0.35s;
  color:#008CFF;
  font-weight: 100;
}
.tab-content-live {
  max-height: 0;
  padding: 0 30px;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
.tab-close-live {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #fff;
  cursor: pointer;
}
.tab-close-live:hover {
  background: #fff;
}
input:checked + .tab-label-live {
  background: #fff;
}
input:checked + .tab-label-live::after {
  transform: rotate(90deg);
}
input:checked ~ .tab-content-live {
  max-height: 100vh;
  padding: 30px;
  margin-top:-7px;
}
.tabboxForLive
{
  overflow:hidden;
}
.intBoxForLive
{
  float: left;
  width: 362px;
}
.kpiBoxForLive{
  float: left;
  /*margin-top: 73px;*/
  margin-left: 4px;
  width:500px;
}
.sentimentText
{
  width:110px;
  text-align: left;
  margin-left: 29px;
}
.flagDiv
{
  float: right;
  border: 1px solid #E3EFFF;
  border-radius: 22px;
  padding: 5px 12px 5px 12px;
  margin-left: 13px;
  color: #24527E;
}
.flagSize
{
  height:30px;
  width:30px;
}

.tabset_discover > input[type="radio"] {
	position: absolute;
	left: -200vw;
  }

.tabset_discover .tab-panel-discover {
	display: none;
  }
  
  .tabset_discover > input:first-child:checked ~ .tab-panels > .tab-panel-discover:first-child,
  .tabset_discover > input:nth-child(3):checked ~ .tab-panels > .tab-panel-discover:nth-child(2),
  .tabset_discover > input:nth-child(5):checked ~ .tab-panels > .tab-panel-discover:nth-child(3),
  .tabset_discover > input:nth-child(7):checked ~ .tab-panels > .tab-panel-discover:nth-child(4),
  .tabset_discover > input:nth-child(9):checked ~ .tab-panels > .tab-panel-discover:nth-child(5),
  .tabset_discover > input:nth-child(11):checked ~ .tab-panels > .tab-panel-discover:nth-child(6) {
	display: block;
  }
  .tab-panel-discover
  {
	height:697px;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
  }

  .tabset_discover > label {
    position: relative;
    display: inline-block;
    /*padding: 15px 40px 15px 40px;*/
    border: 10px 30px 0px 30px;
    margin: 0px;
    left:31px;
    border-radius: 12px 12px 0px 0px;
    cursor: pointer;
    font-weight: normal;
    text-align: center;
    font-size: 16px;
      /*color: #93b1d1;*/
    }
    .tabDeactive_discover
    {
    padding: 15px 40px 20px 40px;
    background: #e3efff;
    color: rgba(0,140,255,1);
    top:10px;
    z-index: 9;
    box-shadow: -8px 5px 12px -15px rgba(0,140,255,0.75);
    }
    .tabset_discover > label::after {
    content: "";
    position: absolute;
    left: 15px;
    width: 22px;
    height: 4px;
    /*background: #8d8d8d;*/
    }
    
    .tabset_discover > label:hover,
    .tabset_discover > input:focus + label {
    color: #24527e;
    }
    .tabset_discover > input:checked + label {
    color: #24527e;
    font-size: 18px;
    background: #F8FBFF;
    margin-bottom: unset;
    top:5px;
    font-weight:600;
    box-shadow: none;
    padding: 15px 40px 15px 40px;
    text-decoration: underline;
    }
    .tabset_discover {
      /*max-width: 65em;*/
      margin-top:14px;
      }
    .hideTab_discover
    {
      z-index: 10;
      position: relative;
    }
    .autoScrollforLive
    {
      height:705px;
      overflow-y: auto;
      margin-top:20px;
      position: relative;
      padding: 0px 16px 0px 0px;
    }

    .toolTipfor404{
      position: relative;
      width: 790px;
      height: auto;
      background-color: #FFF;
      border-radius: 12px;
      padding: 30px;
      margin-top:60px;
    }
    
    .toolTipfor404 h3{
      color: #008CFF;
      font-size: 60px;
      /*font-weight: 600;
      font-style: italic;*/
      margin-bottom: 0px;
      font-family: Averta-Light;
    }
    
    .toolTipfor404 p{
      color: #24527E;
      font-size: 24px;
      font-weight: 300;
      /*font-style: italic;*/
      margin-top: 17px;
      margin-bottom: 0px;
    }
    
    /*.toolTip1404{
      left: 288px;
      top: 20px;
    }*/

    .toolTipfor404::after{
      content: " ";
      position: absolute;
      transform: rotate(45deg);
      top: 108px;
      left: -8px;
      width: 40px;
      height: 40px;
      border-bottom: solid 40px #FFF;
      border-right: solid 20px #FFF;
      border-left: solid 20px transparent;
      border-top: solid 20px transparent;
    }


  /*ACCESS DENIED*/
  .toolTipforDenied{
    position: relative;
    width: 630px;
    height: auto;
    background-color: #FFF;
    border-radius: 12px;
    padding: 30px;
    margin-top:60px;
  }
  
  .toolTipforDenied h3{
    color: #008CFF;
    font-size: 60px;
    /*font-weight: 600;
    font-style: italic;*/
    font-family: Averta-Light;
    margin-bottom: 0px;
  }
  
  .toolTipforDenied p{
    color: #24527E;
    font-size: 24px;
    font-weight: 300;
    /*font-style: italic;*/
    margin-top: 17px;
    margin-bottom: 0px;
  }
  

  .toolTipforDenied::after{
    content: " ";
    position: absolute;
    transform: rotate(45deg);
    top: 108px;
    left: -12px;
    /*background-color: #FFF;
    width: 30px;
    height: 30px;*/

    width: 30px;
    height: 30px;
    border-bottom: solid 20px #FFF;
    border-right: solid 20px #FFF;
    border-left: solid 20px transparent;
    border-top: solid 20px transparent;
  }
  .sentimentImg
  {
    width:20px;
    height:20px;
    margin-right: 8px;
  }
  .colorSentiment0
  {
    color: #29CC75!important;
    /*font-size: 54px!important;*/
  }
  .colorSentiment1
  {
    color: #FF3153!important;
    /*font-size: 46px!important;*/
  }
  .colorSentiment2
  {
    color: #87B0E6!important;
    /*font-size: 38px!important;*/
  }
  .colorSentiment3
  {
    color: #008CFF!important;
    /*font-size: 30px!important;*/
  }
  /*PRE BUILT BOT*/
  
  .pbb_banner_text
  {
    width: 400px;
    font-size: 36px;
    font-family: Averta-Light;
    margin-top: 32px;
    margin-left: 36px;
    position: absolute;
    color: #FFFFFF;
    font-weight: 100;
    line-height: 43px;
    z-index: 20;
  }
  .pbb_category
  {
    position: absolute;
    width: 100%;
    height: 66px;
    bottom: 16px;
    padding: 10px 0px 10px 40px;
  }
  .btn_pbb_category{
    background-color: #fff!important;
    border: 1px solid #FFF!important;
    color: #008CFF!important;
    margin-right: 20px!important;
    padding: 8px 22px!important;
    width: auto!important;
  }
  .btn_pbb_category[disabled]{
    background-color: #93b1d1!important;
    border: 1px solid #93b1d1!important;
    color: #FFFFFF!important;
    margin-right: 20px!important;
    padding: 8px 22px!important;
    width: auto!important;
  }
  .changeBorder
  {
    border: 1px solid #008CFF!important;
  }
  .pbb_board_details
  {
    overflow: hidden;
    width:230px;
    float: left;
    position: relative;
  }
  .pbb_board_heading
  {
    font-family: Averta-Semibold;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #24527E;
    height:35px;
  }
  .pbb_board_text
  {
    font-family: Averta-Regular;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #24527E;
    margin-top: 8px;
  }
  .boardImgAdd
  {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .addImgSize
  {
    width:24px;
    height:24px;
  }
  /*SLIDER*/
  /*.mySlides {display: none;}*/
.pbb_img_banner
{
  display: none;
}
/* Slideshow container */
.slideshow-container {
  /*max-width: 1000px;*/
  position: relative;
  height:331px;
}


.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 4.5s;
  animation-name: fade;
  animation-duration: 4.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {font-size: 11px}
}

.modalPpbBoard {
  display: none; /* Hidden by default */
  position: absolute; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 1046px; /* Full width */
  height: 461px; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 140, 255, 0.45); /* Fallback color */
  border-radius: 8px;
  z-index: 99;
  align-items: center;
  justify-content: center;
}
.pbb_intractionDetails
{
  height:400px;
  position: relative;
}
.img_40
{
  margin: 40px;
}
.pbb_board_description
{
  overflow: hidden;
  width:460px;
  float: left;
  font-size: 18px;
}
.pbb_board_benifits
{
  overflow: hidden;
  width:340px;
  float: right;
  font-size: 18px;
}
.boardImgAddforDetails
{
  position: absolute;
  top: 50px;
  right: 70px;
}
.addImgSizeforDetails
{
  width:60px;
  height:60px;
}
.pbb_back_img
{
    position: absolute;
    top: 12px;
    left: -12px;
}
.smartdashboardAlign
{
    position: relative;
    margin-top: 20px;
    overflow-y: auto;
    height: 776px;
    width: 1052px;
    overflow-x: hidden;
}
.pbb_row
{
  display: flex;
  /*margin-top: 12px;*/
  flex-wrap: wrap;
}
.mainrowForParam
{
  width:230px;
}
.analysisHeader
{
  /*color:#C3E4FF;*/
    color: rgb(36, 82, 126,0.5);
    font-size: 12px;
    font-family: Averta-Bold;
    letter-spacing: 0.5px;
}
.analysisResultbox
{
  border:1px solid rgb(36, 82, 126,0.5);;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  /*height:136px;*/
}
.hyperlink
{
  text-decoration: underline;
}
.interactionNameOvertheboard
{
    border: 2px solid transparent;
    font-size: 15PX;
    padding: 8px;
    cursor: pointer;
    color: #fff;
    font-family: Averta-semibold;
    max-width: 207px;
    /*background-color: rgb(0, 140, 255,1);*/
    background-color: rgb(57, 210, 255);
    position: absolute;
}
/*.bckNamecolor
{
  background-color: rgb(0, 140, 255,1);
  padding:10px;
}*/
.apiKeyStyle
{
    width: 276px;
    word-break: break-word;
    line-height: 14px;
}
.sd_row
{
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
}
.sd_row_heading
{
  font-size: 18px;
  color:#24527E;
  font-family: Averta-Semibold;
  margin-bottom:3px;
}

.sd_both_heading
{
  display: flex;
  flex-wrap: wrap;
}
.img_4blocks_sd
{
  width: 238px;
}
.img_bl1_sd
{
  width:536px;
}
.img_bl1_sd2
{
  width:415px;
  /*margin-left: 4px;*/
}
.sd_row_2
{
  width:553px;
}
.sd_row_3
{
  width:436px;
}
.intaerctions_sd
{
  background-color: #fff;
  border-radius: 6px;
  width: 235px;
  overflow: hidden;
  padding: 0px 16px;
  height: 160px;
}
.textblock_sd
{
  
}
.sd_row_1
{
  display: flex;
  flex-wrap: wrap;
}
.w-99
{
  width:99px;
}
.w-102
{
  width:102px;
}
.txtSubheader_sd
{
  font-size: 40px;
  font-family: Averta-Light;
  color:#24527E;
}
.txtsmall_sd
{
  font-size: 12px;
  font-family: Averta-Semibold;
  color:#93B1D1
}
.img80_sd
{
  width:30px;
}
.imgtext_sd
{
  font-size: 14px;
  font-family: Averta-Regular;
  color:#008CFF
}
.imgemoji_sd
{
  width:35px;
  height:35px;
}
.customTable
{
    background-color: #fff!important;
    border-radius: 6px!important;
    width: 536px!important;
}
.tdcolor_sd
{
  color:#24527E;
  font-size: 12px;
  font-family: Averta-Regular;
}
.customTable thead tr th{
  border-top:1px solid #fff;
  border-bottom:1px solid #E3EFFF;
}
.customTable tbody tr td{
  border-top:1px solid #E3EFFF;
}
.img_td
{
  height:20px;
  width:20px;
}
.modalBoardDesc {
  display: none; /* Hidden by default */
  position: absolute; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 1045px; /* Full width */
  height: 372px; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 140, 255, 0.45); /* Fallback color */
  border-radius: 8px;
  z-index: 99;
  align-items: center;
  justify-content: center;
}
.modal_content_board_Desc
{
  background-color: #f2f7ff;
    padding: 20px;
    padding-top: 0px;
    width: 358px;
    height: 217px;
    border-radius: 18px;
    box-shadow: 0px 12px 24px rgba(0, 112, 204, 0.566434);
    position: relative;
    margin: 7% 34%;
}
.botsBox{
  width:458px;
  height:179px;
  background-color: #fff;
  border-radius: 6px;
}
.bots3box{
  display:flex
}
.botView_img{
  width:44px;
  margin-top:16px;
  height:74px;
}
.imgText_bot
{
  color: #93B1D1;
  font-size: 12px;
  font-family: Averta-Semibold;
}
.small_button_details
{
	background-color: #93B1D1;
    border: 1px solid #93B1D1;
    color: #fff;
    padding: 4px 10px;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    border-radius: 30px;
    outline: none;
    font-family: "Averta-Semibold";
}
.small_button_details:focus,.small_button_details:active
{
	outline: none !important;
	box-shadow: none;
}
.IpBox{
  width: 536px;
  height:266px;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  /*background:#ffffff url("images/screen/background_ip_sd.svg") no-repeat center right;*/
  box-shadow: 0px 12px 34px rgb(0, 140, 255,0.1);
}
.row_sd
{
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.row_sd_botbox
{
  display: flex;
  flex-wrap: wrap;
}
.col-md-5-sd{
  overflow: hidden;
}
.col-md-12-sd
{
  width: 415px;
  margin-left: 12px;
}
.col-md-4-sd
{
    width: 30%;
}
.graph_img_sd
{
  width:536px;
}
.notchUp_sd
{
  position: absolute;
  top:-40px;
  left:220px;
}
.notchDown_sd
{
  position: absolute;
  top:218px;
  left:220px;
}
.carouselforProfile{
    width: 327px!important;
    margin-left: 4px!important;
}
.roundboxfroNotch_sd_top{
  position: absolute;
    top: -17px;
    left: 236px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fff;
}
.roundboxfroNotch_sd_bottom{
  position: absolute;
    top: 243px;
    left: 236px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fff;
}
.notch_sd{
  margin-left: 11px;
  margin-top: 8px;
}
/*SIDEMENU COLLAPSE*/
.logoImg_collapse
{
  width:30px;
  height:18px;
}
#Side_Menu___Channels_collapse
{
    width: 80px;
    height: 895px;
    overflow: visible;
    background-color: rgb(242, 247, 255);
    float: left;
}
#side_menu_collapse
{
  padding: 0px 25px;
}
#bl_logo___blue_collapse
{
    width: 80px;
    height: 32px;
    margin-top: 41px;
    overflow: visible;
}

#Group_collapse
{
    
}
.tab_collapse
{
    width: 100%;
    overflow: hidden;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,140,255,1);
    margin-top: 25px /*padding: 10px 10px;*/;
}
.divider_collapse
{
    border-bottom: 1px solid rgba(147, 177, 209, 0.3);
    margin: 20px 0px;
    width: 37px;
}
.fixedFooter_collapse
{
    position: absolute;
    bottom: 20px;
    left: 10px;
    width: 60px;
    height: 143px;
}
#User_collapse {
  height: 60px;
  overflow: visible;
  border: 1px solid rgba(147, 177, 209, 0.3);
  border-radius: 50%;
  margin-top: 12px;
  width:60px;
}
#Group_5_da_collapse{
    
}
#Photo_collapse {
  width: 40px;
  height: 40px;
  overflow: visible;
  border-radius: 50%;
  margin-top: 9px;
  margin-left: -6px;
}
#_2019_Copyright_Blue_collapse
{
    overflow: visible;
    width: 80px;
    text-align: center;
    font-family: Averta-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(147,177,209,1);
    margin-top: 8px;
    word-break: break-word;
    margin-left: -12px;
}
.overlay_collapseble_feed {
  width: 200px;
  position: absolute;
  z-index: 1;
  top: 324px;
  left: 82px;
  transition: 0.5s;
  border: 1px solid rgb(147,177,209,0.3);
  display: none;
  background-color:#fff;
  border-radius: 6px;
}

.overlay-content-collapseble-feed:after {
  /*content:'';
  display:block;
  position:absolute;
  top:1px;
  left:-20px; 
  width:0;
  height:0;
  border-color: transparent #008cff transparent transparent;
  border-style: solid;
  border-width: 10px;*/
    content: '';
    border: solid rgb(147,177,209,0.3);
    border-width: 0 1px 1px 0;
    position: absolute;
    top: 1px;
    left: -8px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    width: 15px;
    height: 15px;
    background-color: white;
}

.overlay-content-collapseble-feed{
  text-align: left;
  color: #008cff;
  font-size: 16px;
  position: relative;
}
.overlay_collapseble_Hamburger {
  width: 200px;
  position: absolute;
  z-index: 1;
  top: 4px;
  left: 69px;
  transition: 0.5s;
  display: none;
  background-color:#fff;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  border-top: 1px solid rgb(147,177,209,0.3);
  border-bottom: 1px solid rgb(147,177,209,0.3);
  border-right: 1px solid rgb(147,177,209,0.3);
}

.overlay-content-collapseble-Hamburger{
  text-align: left;
  color: #008cff;
  font-size: 16px;
  position: relative;
}
/*.overlay-content-collapseble-Hamburger:after {
  content:'';
  display:block;
  position:absolute;
  top:10px;
  left:-20px; 
  width:0;
  height:0;
  border-color: transparent #008cff transparent transparent;
  border-style: solid;
  border-width: 10px;
}*/
.closefeed
{
  position: absolute;
}
.fa-bars{
  font-size: 40px;
}
.NavActive_Tab_collapse {
  width: 76px;
    border: 1px solid #fff;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
    background-color: #fff;
    box-shadow: 0px 5px 15px -12px rgba(0,140,255,0.35);
    height: 60px;
    left: 6px;
    position: absolute;
}
.NavActive_Tab_submenu
{
  width: 76px;
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
  background-color: #fff;
  box-shadow: 0px 5px 15px -12px rgba(0,140,255,0.35);
  height: 46px;
  margin-left: -6px;
  border-top: 1px solid rgb(147,177,209,0.3);
  border-bottom: 1px solid rgb(147,177,209,0.3);
  border-left: 1px solid rgb(147,177,209,0.3);
}
/*AGENT CHAT*/
.middlePanelOthers_agentChat {
  float: left;
  width: 1245px;
  margin-left: 56px;
}
#agent_chat_main_screen
{
  width: 1245px;
  height: 895px;
  margin-top: 20px;
  overflow: visible;
  padding-top: 10px;
  background-color: #f2f7ff;
  border-radius: 6px;
  padding-right: 40px;
}
.col-md-3_agent_chat
{
  float: left;
  width:295px;
}
.col-md-5_agent_chat
{
  float: left;
  width: 612px;
}
.col-md-4_agent_chat
{
  float: left;
  width:258px;
}
.attention_text
{
  color: #F44336;
  font-size: 12px;
  font-family: Averta-Semibold;
}
.name_text_agent
{
  color: #24527E;
  font-size: 18px;
  font-family: Averta-Semibold;
  width:156px;
}
.address_text_agent
{
  /*color: #24527E;*/
  font-size: 12px;
  font-family: Averta-Regular;
  color: rgb(36,82,126,0.3);
}
.row_agent
{
    display: flex;
    flex-wrap: wrap;
}
.type_agent
{
  font-size: 12px;
  font-family: Averta-Bold;
  color: rgb(36,82,126,0.3);
}
.agent_chat_box
{
  overflow: hidden;
  padding: 6px 13px;
}
.agent_status_dot
{
    background-color: #29CC75;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    display: inline-block;
}
.mainBorderAgentIcon
{
  border: 1px solid rgb(0,140,255,0.1);
  border-radius: 50%;
  padding: 7px;
  width: 58px;
  height: 58px;
  padding: 2px;
}
.agentBorderDiv
{
  border: 1px solid #008CFF;
  border-radius: 50%;
  padding-top: 2px;
  width: 51px;
  height: 51px;
  text-align: center;
}
.agentIcon
{
  height:44px;
  width: 44px;
}

.user_chat_list
{
    height: 540px;
    overflow-y: auto;
}
.user_chat_box
{
  /*height:70px;*/
  padding: 6px 8px 6px 12px;
  border-bottom: 1px solid rgb(147,177,209,0.3);
  overflow: hidden;
  margin-right: 8px;
}
.chatIcon
{
  width:32px;
  height:32px;
}
.userName_chatBox_agent_chat
{
  color: #008CFF;
  font-size: 12px;
  font-family: Averta-Semibold;
}
.nameAddressBox
{
  float: left;
  width: 139px;
}
.userAddr_chatBox_agent_chat
{
  color: #24527E;
  font-size: 9px;
  font-family: Averta-Semibold;
}
.usertxt_chatBox_agent_chat
{
  color: rgb(36,82,126,0.3);
  font-size: 9px;
  font-family: Averta-Regular;
  margin-top: 5px;
}
.timebox
{
  color: rgb(36,82,126,0.3);
  font-size: 10px;
  font-family: Averta-italic;
  margin-left: -1px;
}
.userOriented_chat_window
{
  border-top:1px solid #ffffff;
  border-left:1px solid #ffffff;
  border-right:1px solid #ffffff;
  border-radius: 6px;
  height:800px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0,140,255,0.11);
}
.txtTypeChat_chat_agent {
  width: 560px;
  line-height: 18px;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  height: 92px;
  border: 2px solid #008CFF;
}
.sentiment_chat_box{
  box-shadow: 0px 0px 6px 0px rgba(0,140,255,0.11);
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sentiment_header_chat_box
{
  color: #24527E;
  font-size: 14px;
  font-family: Averta-Semibold;
}
.img_process_color
{
  color: #24527E!important;
}
.userChatIconforChatBox
{
    float: left;
    margin-top: 10px;
    height: 48px;
    width: 48px;
}
.chat-message-user-chat-box {
  float: right;
  padding: 8px 16px;
  background-color:#E3EFFF;
  width: 336px;
  color: #24527E;
  position: relative;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 12px;
  font-family: 'Averta-Regular';
}
.chat-message-bot-chat-box
{
	float:left;
	padding:8px 16px;
	background-color:#008cff;
	width: 314px;
	color: #E3EFFF;
	position: relative;
	border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 12px;
  font-family: 'Averta-Regular';
}
.channelIcon_agent_chat
{
  width:20px;
  height:20px;
  margin-top: 8px;
}
.PreviousChatDetails_box
{
  background-color: #fff;
  padding: 11px 12px;
  border-radius: 6px;
  margin-top: 10px;
  color: #24527E;
  font-size: 12px;
  font-family: Averta-Semibold;
  /*width: 240px;*/
  /*float: left;*/
  box-shadow: 0px 0px 6px 0px rgba(0,140,255,0.11);
  width:280px;
  height: 90px;
}
.semibold
{
  font-family: Averta-Semibold;
}
.prevoius_details_text
{
  font-family: 'Averta-Regular';
  font-size: 10px;
  width: 168px;
  color: rgb(36,82,126,0.4);
  text-align: justify;
}
.box_agent_browserdetails
{
  font-family: Averta-Semibold;
  font-size: 10px;
}
.transferIcon_agent_chat
{
  width:16px;
  height:16px;
}
.refresh_agent_chat_sentiment
{
    overflow: hidden;
    text-align: right;
    color: #008cFF;
    font-size: 12px;
    font-family: Averta-Semibold;
}
.bgWhite
{
  background-color: #fff!important;
}
.agent_chat_attach
{
    position: absolute;
    top: 57px;
    left: 30px;
}
.agent_chat_send
{
  position: absolute;
  top: 53px;
  left: 532px;
}
.attentionNumber
{
  width: 29px;
    height: 29px;
    padding-top: 14px;
    border-radius: 50%;
    background-color: #F44336;
    color: #fff;
    font-size: 22px;
    font-family: Averta-Bold;
    margin-top: -2px;
    line-height: 0px;
    padding-left: 7px;
}
.socialIcon_agent_chat
{
  /*padding: 5px;
  margin-left: -13px;*/
}
.fadedColor
{
  color: rgb(36,82,126,0.3);
}
.searchConversion::placeholder {
  color: rgb(36,82,126,0.3);
  font-size: 12px;
  font-family: 'Averta-italic';
}
.borderBottomTd
{
  padding: 10px 0px;
  border-bottom: 1px solid rgb(147,177,209,0.3);
}
.markerSentiment
{
  padding: 0px;
    line-height: 0px;
    margin-left: 20px;

}
.markerText
{
  font-size: 10px;
  font-family: 'Averta-italic';
}
.delete_ac
{
  font-size: 12px;
  font-family: 'Averta-Semibold';
}
.switch_ac {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
}

.switch_ac input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.slider_ac {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E3EFFF;
  -webkit-transition: .4s;
  transition: .4s;
  border: 1px solid rgba(0,140,255,0.10);
}
.slider_ac:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border: 1px solid rgba(0,140,255,0.30);
}

input:checked + .slider_ac {
  background-color: #2196F3;
}

input:checked + .slider_ac:before {
  position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: -3px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid rgba(0,140,255,0.10);
}

input:focus + .slider_ac {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider_ac:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider_ac.round {
  border-radius: 34px;
}

.slider_ac.round:before {
  border-radius: 50%;
}
.unread_number_ac
{
  width: 14px;
  height: 14px;
  border: 1px solid #008CFF;
  /* padding: 5px; */
  border-radius: 50%;
  background-color: #008CFF;
  color: #fff;
  text-align: center;
  font-size: 10px;
  position: absolute;
  left: 50px;
  top: 20px;
  align-items: center;
}
.dayforagentchat
{
    text-align: center;
    font-size: 9px;
    font-family: Averta-Bold;
    color: rgb(36,82,126,0.3);
    margin-bottom: 8px;
}
.agoTimeAgentChat
{
  width:378px;
  font-size: 9px;
  font-family: Averta-italic;
  color: rgb(36,82,126,0.3);
}
.divHeaderTxt_ac
{
	  margin-top: 15px;
    margin-left: 12px;
}
.redMarkNotification_ac
{
    background-color: #F44336;
    color: #fff;
    padding: 2px 9px;
    border-radius: 18px;
    font-size: 9px;
    font-family: Averta-Bold;
}
.italic
{
  font-family: Averta-italic;
}
.SentimentHeaderTxt_ac {
  text-align: left;
  font-family: Averta-Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(36,82,126,1);
}
.customerEmail_ac {
  text-align: left;
  font-family: Averta-italic;
  font-size: 9px;
  color: #93b1d1;
}
.usertxt_chatBox_agent_chat_ir {
  color: rgb(36,82,126,1);
  font-size: 10px;
  font-family: Averta-Regular;
  margin-top: 5px;
}
.averta-italic
{
  font-family: Averta-Italic;
}
/*DIGITAL BROADCASTING*/
#Digital_broadcasting {
	position: absolute;
	width: 1048px;
	height: 980px;
	left: 331px;
	top: 84px;
	overflow: visible;
  background-color: #f2f7ff;
  border-radius: 6px;
}
.row_content_1
{
  height:895px;
  display: flex;
  flex-wrap: wrap;
  padding:23px 0px 0px 30px;/**/
}
.col-md-8-content
{
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 762px;
  border-radius: 6px;
  padding: 22px 60px 0px 35px;
  box-shadow: 0px 12px 34px rgba(0, 140, 255, 0.1);
}
.col-md-4-content
{
  width: 218px;
  margin-left: 35px;
  margin-top:14px;
}
.content_header_text
{

}
.db_content_header_text
{
  font-size: 18px;
  font-family: Averta-Semibold;
  color:#24527E;
}
.db_content_details
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 39px;
}
.db_content_frm_details
{
  
  
}
.db_content_img_details
{
  
}
.db_img_src
{
  background-color: #E3EFFF;
  border-radius: 6px;
  padding: 10px;
}
.db_img_button
{
  padding: 15px 25px;
  width:170px;
  background-color: #fff;
  border: 1px solid rgb(147,177,209,0.3);
  color:#24527E;
  font-size: 14px;
  font-family: Averta-Regular;
  border-radius: 12px;
  box-shadow: 0px 5px 10px rgba(0, 140, 255, 0.3);
  
}
.db_button_audio
{
  width:32px;
  height: 32px;
}
.db_add_button
{
  width: 115px;
  padding: 10px;
  color:#24527E;
  font-size: 12px;
  font-family: Averta-Regular;
  background-color: #fff;
  border: 1px solid rgb(147,177,209,0.3);
  border-radius: 18px;
  box-shadow: 0px 5px 10px rgba(0, 140, 255, 0.3);
}
.db_button_add
{
  width:16px;
  height: 16px;
}
.db_all_step
{
  position: relative;
}
.db_serial_step
{
  width:130px;
}
.db_step_box
{
  border:2px solid #24527E;
  background-color: transparent;
  width:24px;
  height:24px;
  font-size: 14px;
  color:#24527E;
  font-family: Averta-Extrabold;
  text-align: center;
  margin-top: 8px;
  border-radius: 2px;
}
.db_step_divider
{
  display: flex;
  flex-wrap: wrap;
}
.db_step_header
{
  width: 147px;
  /*margin-left: 9px;*/
  font-size: 14px;
  font-family: Averta-Semibold;
  color:#24527E;
}
.db_step_subtext
{
  color:#D9E5F3;
  font-size: 12px;
  font-family: Averta-Bold;
  width: 147px;
  /*margin-left: 35px;*/
}
.db_step_line
{
    border: 2px solid #D9E5F3;
    height: 84px;
    width: 0px;
    position: absolute;
    top: 38px;
    margin-left: 12px;
}
.db_step_box_disable
{
  border:2px solid #93B1D1;
  background-color: transparent;
  width:24px;
  height:24px;
  font-size: 14px;
  color:#93B1D1;
  font-family: Averta-Extrabold;
  text-align: center;
  margin-top: 8px;
  border-radius: 2px;
}
.db_step_subtext_disable
{
  color:#93B1D1;
  font-size: 12px;
  font-family: Averta-Semibold;
  margin-left: 9px;
  width: 94px;
}
.db_step_line_2
{
    border: 2px solid #D9E5F3;
    height: 48px;
    width: 0px;
    position: absolute;
    top: 158px;
    margin-left: 12px;
}
.db_step_line_3
{
    border: 2px solid #D9E5F3;
    height: 48px;
    width: 0px;
    position: absolute;
    top: 243px;
    margin-left: 12px;
}
.db_area
{
  height:107px;
}
.db_next_button
{
  box-shadow: 0px 9px 10px rgba(0, 140, 255, 0.5);
  font-size: 12px;
  font-family: 'Averta-Extrabold';
}
.db_active_step
{
  width:86px;
  margin-left: 9px;
}
.db_label_text
{
  line-height: 12px;
  margin-bottom: 10px;
}
/*CONTENt2*/
.col-md-8-content_2
{
  display: flex;
  flex-wrap: wrap;
  width: 762px;
  /*padding: 22px 60px 0px 35px;*/
  box-shadow: 0px 12px 34px rgba(0, 140, 255, 0.1);
  /*border: 1px solid red;*/
}
.col-md-3_content_2
{
  background-color: #fff;
  width:341px;
  padding:22px 13px 0px 13px;
  border-radius: 6px;
}
.col-md-5_content_2
{
  background-color: #fff;
  width:406px;
  margin-left: 15px;
  padding:22px 0px 0px 20px;
  border-radius: 6px;
}
.db_channel_container_content_2
{
  margin-top: 41px;
}



.db_fileUploadHolder_content2
{
  width:348px;
  height:43px;
}
.fileuploader_content2
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.fileupload_text_content2
{
  font-size: 12px;
  font-family: Averta-Regular;
  color:#24527E;
}
.fileupload_link_content2
{
  font-size: 12px;
  font-family: Averta-Semibold;
  color:#008CFF;
}
.filename_text_content2
{
  font-size: 12px;
  font-family: 'Averta-italic';
  color:#93B1D1;
}
.fileremove_text_content2
{
  font-size: 12px;
  font-family: 'Averta-italic';
  color:#E02020;
}
.dividerfromuploader_content2
{
  border:1px solid #D9E5F3;
  width:358px;
  margin-top: 17px;
}
.db_content2_subheader_text
{
  font-size: 14px;
  font-family: Averta-Semibold;
  color:#24527E;
}
.db_content2_messageContainer
{
  width:358px;
  border:1px solid #D9E5F3;
  border-radius: 12px;
}
.db_content2_message_1
{
  margin-top:20px;
  padding:0px 0px 16px 20px ;
  display: flex;
  flex-wrap: wrap;
  
}
.db_content_2_text_icon
{
  width:22px;
  height:22px;
}
.db_content_2_text_icon_name
{
  font-size: 10px;
  font-family: Averta-regular;
  color:#24527E;
  margin-left: 6px;
  margin-top: 5px;
}
.db_content2_textHolder
{
  margin-left: 32px;
  margin-top: 6px;
}
.db_content_2_checkbox_text
{
  font-size: 12px;
  font-family: Averta-regular;
  color:#24527E;
}
.checkbox_db_content_2
{
  width:16px;
  height:16px;
}
.db_content_2_est_text
{
  font-size: 10px;
  font-family: 'Averta-italic';
  color:#93B1D1;
}
.db_msgr_samll
{
  border:1px solid #D9E5F3;
  width:147px;
}
.db_content_2_selectHolder
{
  display: flex;
  flex-wrap: wrap;
}
.selectFordb
{
  border: 2px solid #008CFF!important;
  color: #008CFF;
  border-radius: 6px;
  font-size: 12px;
  font-family: Averta-Semibold;
}
.db_content_2_select_text
{
  color: #008CFF;
  font-size: 12px;
  font-family: 'Averta-Semibold';
  margin-left: 21px;
  /*margin-top: 10px;*/
}
.db_content_2_information
{
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
}
.db_content_2_inf_text
{
  font-size: 12px;
  font-family: 'Averta-italic';
  color:#24527E;
  margin-left: 6px;
}
.db_step_done_box {
  background-color: transparent;
  width: 24px;
  height: 24px;
  font-size: 14px;
  color: #24527E;
  font-family: Averta-Extrabold;
  text-align: center;
  margin-top: 8px;
}
.db_step_line_ct_2
{
    border: 2px solid #D9E5F3;
    height: 48px;
    width: 0px;
    position: absolute;
    top: 38px;
    margin-left: 12px;
}
.db_step_header_ct_2
{
  width:111px;
  /*margin-left: 9px;*/
  font-size: 14px;
  font-family: Averta-Semibold;
  color:#24527E;
}
.db_step_subtext_ct_2
{
  color:#D9E5F3;
  font-size: 12px;
  font-family: Averta-Bold;
  width:147px;
  /*margin-left: 35px;*/
}
.db_step_line_2_ct_2
{
    border: 2px solid #D9E5F3;
    height: 84px;
    width: 0px;
    position: absolute;
    top: 123px;
    margin-left: 12px;
}
.db_row_channel_holder
{
    display: flex;
    flex-wrap: wrap;
}
.col-md-3_channelbox
{
  width:62px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 8px;
}
.channel_icon_size
{
  /*height:22px;*/
  width:22px;
}
.channel_text
{
  font-family: Averta-Semibold;
  font-size: 10px;
  text-align: center;
  color: #24527E;
  line-height: 12px;
}
.channel_sub_text
{
  font-family: Averta-Regular;
  font-size: 10px;
  text-align: center;
  color: #24527E;
  line-height: 12px;
  margin-top: 4px;
}
.activeIcon_channels_db
  {
    border: 3px solid #008cff;
    background-color: #e3efff;
    border-radius:6px;
  }
  .progressFull_channel_icon {
    background-image: url(/static/media/newtick.9ceb7c80.svg);
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: 42px 2px;
}
.row_content_modal
{
  /*display: flex;
  flex-wrap: wrap;*/
}
.modal_header
{
  display: flex;
  flex-wrap: wrap;
}
.content_modal_header
{
  color:#93B1D1;
  font-size: 12px;
  font-family: Averta-Semibold;
}
.content_modal_sub_header
{
  color:#105F89;
  font-size: 22px;
  font-family: Averta-Semibold;
}
.content_upload_tab_holder
{
  /*border:1px solid;*/
}

.tabset_upload_content > input[type="radio"] {
	position: absolute;
	left: -200vw;
  }

.tabset_upload_content .tab-panel-upload-content {
	display: none;
  }
  
  .tabset_upload_content > input:first-child:checked ~ .tab-panels-upload_content > .tab-panel-upload-content:first-child,
  .tabset_upload_content > input:nth-child(3):checked ~ .tab-panels-upload_content > .tab-panel-upload-content:nth-child(2),
  .tabset_upload_content > input:nth-child(5):checked ~ .tab-panels-upload_content > .tab-panel-upload-content:nth-child(3),
  .tabset_upload_content > input:nth-child(7):checked ~ .tab-panels-upload_content > .tab-panel-upload-content:nth-child(4),
  .tabset_upload_content > input:nth-child(9):checked ~ .tab-panels-upload_content > .tab-panel-upload-content:nth-child(5),
  .tabset_upload_content > input:nth-child(11):checked ~ .tab-panels-upload_content > .tab-panel-upload-content:nth-child(6) {
	display: block;
  }
  .tab-panels-upload_content
  {
    background: #E3EFFF;
  }
  .tab-panel-upload-content
  {
	/*height:220px;*/
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    padding-bottom:20px;
  }

  .tabset_upload_content > label {
    position: relative;
    display: inline-block;
    border: 10px 30px 0px 30px;
    margin: 0px;
    left:31px;
    border-radius: 12px 12px 0px 0px;
    cursor: pointer;
    font-weight: normal;
    text-align: center;
    padding: 15px 40px 15px 40px;
    color: #008CFF;
    font-size: 14px;
    font-family: Averta-Regular;
    }
    /*.tabDeactive_upload_content
    {
      padding: 15px 40px 20px 40px;
      background: transparent;
      color: rgba(0,140,255,1);
      top:10px;
      font-size: 14px;
      font-family: Averta-Regular;
    }*/
    .tabset_upload_content > label::after {
    content: "";
    position: absolute;
    left: 15px;
    width: 22px;
    height: 4px;
    /*background: #8d8d8d;*/
    }
    
    .tabset_upload_content > label:hover,
    .tabset_upload_content > input:focus + label {
    color: #24527e;
    }
    .tabset_upload_content > input:checked + label {
    color: #24527e;
    font-size: 14px;
    background: #E3EFFF;
    margin-bottom: unset;
    font-family: Averta-Regular;
    box-shadow: none;
    padding: 15px 40px 15px 40px;
    text-decoration: underline;
    }
    .drgaImgHolder
    {
      text-align: center;
      padding-top: 30px;
    }
    .dragTextHolder
    {
      text-align: center;
      font-size: 12px;
      color: #93B1D1;
      font-family: Averta-Regular;
    }
    .orTextHolder
    {
      text-align: center;
      font-size: 10px;
      color: #24527E;
      font-family: Averta-Semibold;
    }
    .browseButtonHolder
    {
      text-align: center;
    }
    .personalize_container
    {
      padding-top:11px;
      padding-left: 27px;
    }
    .list_container_content
    {
      display: flex;
      flex-wrap: wrap;
    }
    .list_heading
    {
      font-size: 12px;
      font-family: Averta-regular;
      color: #24527E;
    }
    .list_name
    {
      font-size: 12px;
      font-family: Averta-italic;
      color: #93B1D1;
    }
    .property_container
    {
      display: flex;
      flex-wrap: wrap;
    }
    .table_container
    {
      border-radius: 6px;
      width:392px;
      background-color: #E3EFFF;
      padding: 10px 12px;
      height: 290px;
      overflow: hidden;
      position: relative;
    }
    .table_container_layer
    {
      border-radius: 6px;
      width:392px;
      background: linear-gradient(180deg, #E3EFFF 0%, rgba(224, 234, 246, 0.901688) 30.76%, rgba(216, 216, 216, 0.0001) 85%);
      transform: rotate(-180deg);
      height: 290px;
      top: 0px;
      left:0px;
      position: absolute;
    }
    .msg_container
    {
      width:228px;
      margin-left: 20px;
    }
    .btn_content3 {
      background-color: #fff!important;
      border: 1px solid #008CFF!important;
      color: #008CFF!important;
      padding: 5px 18px!important;
      font-size: 12px!important;
      font-family: Averta-Regular!important;
      height:24px !important;
      line-height: 12px;
      width: auto!important;
  }
  .list-table thead th{
      font-size: 6px;
      font-family: Averta-Regular;
      color: #fff;
      background-color: #24527E;
      letter-spacing: 0px;
  }
  .list-table tbody td{
    font-size: 6px;
    font-family: Averta-regular;
    color: #24527E;
    background-color: #FFF;
    word-break: break-word;
}
.upload_button_browse {
  color: #FFF!important;
  background-color: #008CFF!important;
  padding: 9px 22px !important;
  border-radius: 30px !important;
  font-size: 12px!important;
  font-family: 'Averta-Extrabold'!important;
  cursor: pointer;
  letter-spacing: 1px;
}
.col-md-8-content_4
{
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    width: 762px;
    border-radius: 6px;
    padding: 22px 0px 0px 31px;
    box-shadow: 0px 12px 34px rgba(0, 140, 255, 0.1)
}
.col-md-12-content
{
  display: flex;
  flex-wrap: wrap;
  width: 712px;
  justify-content: space-between;
}
.content_4_text_holder
{
  width: 175px;
}
.content_4_img_holder
{
  width:217px;
}
.content_4_video_holder
{
  width:247px;
}
.text_content_4
{
  font-size: 14px;
  font-family: Averta-Semibold;
  color: #24527E;
}
.db_vdo_player_content_4
{
    background-color: #E3EFFF;
    border-radius: 6px;
    padding: 14px;
}
.textBox_holder_content_4
{

}
.title_txt
{
  font-size: 14px;
  font-family: 'Averta-Semibolditalic';
  color: #24527E;
  font-weight: bold;
}
.title_name_txt
{
  font-size: 14px;
  font-family: 'Averta-Semibold';
  color: #24527E;
}
.description_txt
{
  font-size: 14px;
  font-family: 'Averta-Regular';
  color: #24527E;
}
.content_4_divider
{
  width: 712px;
  border: 1px solid #D9E5F3;
}
.channel_msg_holder_content_4
{
  width:318px;
}
.channel_wa_holder_content_4
{
  width:181px;
  border:2px solid #E3EFFF;
  padding: 13px 15px 13px 15px;
  border-radius: 6px;
}
.channel_msgr_holder_content_4
{
  width:181px;
  border:2px solid #E3EFFF;
  padding: 13px 15px 13px 15px;
  border-radius: 6px;
}
.left_content_4_1
{
  border:2px solid #E3EFFF;
  border-radius: 6px;
  padding: 13px 15px 13px 15px;
  cursor: pointer;
}
.up_content_4_label_holder
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.msgtype_content_4
{
  font-size: 10px;
  font-family: 'Averta-italic';
  color: #24527E;
  line-height: 10px;
}
.middile_text_holder
{
  width:116px;
}
.middle_content_holder_content4
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bigSale
{
  font-size: 9px;
  font-family: 'Averta-Bold';
  color: #24527E;
}
.no_audio_text_content_4
{
  font-size: 9px;
  font-family: 'Averta-italic';
  color: #E02020;
  
}
.db_step_line_1_ct_4
{
    border: 2px solid #D9E5F3;
    height: 48px;
    width: 0px;
    position: absolute;
    top: 123px;
    margin-left: 12px;
}
.db_step_line_2_ct_4
{
    border: 2px solid #D9E5F3;
    height: 84px;
    width: 0px;
    position: absolute;
    top: 207px;
    margin-left: 12px;
}
.modal_body_content4
{
  
}
.modal_body_left
{
  width:286px;
}
.left_img_holder_content_4
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ac_4_1
{
  border:2px solid #E3EFFF;
  border-radius: 6px;
  padding-bottom: 6px;/**/
  display: flex;
  flex-wrap: wrap;
}
.img_holder_md_2
{
  margin: 9px 0px 4px 9px;
  border: 2px solid transparent;
}
.img_holder_md_2:hover{
  border: 2px solid #008cff;
  background-color: #e3efff;
  border-radius:6px;
}
.activeImgCrop
{
  border: 2px solid #008cff;
  background-color: #e3efff;
  border-radius:6px;
}
.modal-md-12-c4
{
  display: flex;
  flex-wrap: wrap;
}
.modal-md-6-c4
{
  width: 367px;
  padding:27px 0px 27px 27px;
}
.modal-md-6-c4-color-change
{
  background-color: #E3EFFF;
  width: 366px;
  padding: 92px 25px 0px 25px;
  height: 500px;
}
.change_text_holder
{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 310px;
}
.selected_box
{
  width:106px;
  border: 2px solid #008CFF;
  border-radius: 6px;
  background-color: #CCE2FF;
  font-size: 12px;
  font-family: 'Averta-Semibold';
  color:#24527E;
  padding: 9px 0px 9px 6px;
}
.normal_box
{
  width:106px;
  border-radius: 6px;
  background-color: #CCE2FF;
  font-size: 12px;
  font-family: 'Averta-Semibold';
  color:#24527E;
  padding: 9px 0px 9px 6px;
  border: 2px solid transparent;
}
.normal_desc_box
{
  width:112px!important;
  border-radius: 6px;
  background-color: #CCE2FF;
  font-size: 10px;
  font-family: 'Averta-Semibold';
  color:#24527E;
  padding: 9px 6px 9px 6px;
  border: 2px solid transparent;
  height:75px;
}
.selected_desc_box
{
  width:112px;
  border: 2px solid #008CFF;
  border-radius: 6px;
  background-color: #CCE2FF;
  font-size: 10px;
  font-family: 'Averta-Semibold';
  color:#24527E;
  padding: 9px 6px 9px 6px;
  height: 70px;
}
.left_content_4_2
{
  border:2px solid #E3EFFF;
  border-radius: 6px;
  padding: 13px 15px 13px 15px;
  background-color: #F2F7FF;
}
.send_contact_text_c4
{
  font-size: 12px;
  font-family: 'Averta-Regular';
  color:#24527E;
  margin-left: 4px;
}
.contact_holder_c4
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.est_text
{
  font-size: 10px;
  font-family: 'Averta-italic';
  color:#93B1D1;
  
}
.apply_text
{
  font-size: 12px;
  font-family: 'Averta-Semibold';
  color:#008CFF;
}
.crop_img_holder_c4
{
  padding: 27px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.progressFull_text_icon {
  background-image: url(/static/media/newtick.9ceb7c80.svg);
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: 86px 2px;
}
.progressFull_desc_icon {
  background-image: url(/static/media/newtick.9ceb7c80.svg);
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: 96px 2px;
}
.progressFull_img_icon {
  background-image: url(/static/media/newtick.9ceb7c80.svg);
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: 96px 2px;
}
.normal_box:hover{
    border: 2px solid #008cff;
    background-color: #e3efff;
    border-radius:6px;
}
.normal_desc_box:hover{
  border: 2px solid #008cff;
  background-color: #e3efff;
  border-radius:6px;
}
.activeIcon_text_db
  {
    border: 2px solid #008cff;
    background-color: #e3efff;
    border-radius:6px;
  }
  .text_content_5
  {
    font-size: 14px;
    font-family: 'Averta-Regular';
    color: #24527E;
  }
  .db_c5_header_holder
  {
    display: flex;
    flex-wrap: wrap;
  }
  .db_content_img_details_c5
  {
    border:1px solid #D9E5F3;
    width:325px;
    padding:20px 16px 0px 15px;
    border-radius: 6px;
    height:504px;
  }
  .col-md-8-content_c5 {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    width: 762px;
    border-radius: 6px;
    padding: 22px 35px 0px 35px;
    box-shadow: 0px 12px 34px rgba(0, 140, 255, 0.1);
}
.dividerfromuploader_content2_c5
{
  border:1px solid #D9E5F3;
  width:325px;
  margin-top: 19px;
  margin-left: -17px;
}
.xls_name_c5
{
  font-size: 10px;
  font-family: 'Averta-italic';
  color: #93B1D1;
}
.endList_c5
{
  font-size: 10px;
  font-family: 'Averta-Semibolditalic';
  color: #93B1D1;
  text-align: center;
}
.db_step_line_2_ct_5
{
    border: 2px solid #D9E5F3;
    height: 48px;
    width: 0px;
    position: absolute;
    top: 207px;
    margin-left: 12px;
}
.db_postnow_button
{
  border: 2px solid #008cff!important;
  background-color: #f2f7ff!important;
  color: #008cff!important;
}
.cropImgHeightWidth
{
  width: 628px;
  height: 297px;
}
.img_bordered_pbb
{
  border:1px solid #D5E2F2;
  border-radius: 6px;
}
/*ANALYZE RESULTS*/
.sheet-tab
{
    color: #24527e !important;
    padding: 0px 40px!important;
}
.interaction_performace_holder
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.csvHeaderSelectHolder
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.react-time-picker__wrapper
{
    border-radius: 30px;
    padding: 6px 12px;
    border: 1px solid #a9d7ff!important;
}
.react-time-picker__inputGroup__input
{
  color: #24527e;
  /*font-size: 14px;*/
  font-family: Averta-Semibold;
}
.checkIconColor
{
  color: #008CFF !important;
  font-size: 24px;
}
.checkCrossColor
{
  color:#F44336;
  font-size: 24px;
}
.passwordNotMatchTxt
{
  font-size: 12px;
  color:#F44336;
}
.db_img
{
  width:117px;
  height:117px;
}
.video_loader_db
{
  width: 245px;
  height: 145px;
}
.db_img_content_4
{
  width:200px;
  height:200px;
}
.db_img_vdo
{
  width:240px;
  height:117px;
}
.alignBoth_db{
display: flex;
justify-content: space-between;
flex-wrap: wrap;
width: 260px;
}